import React from 'react'

export default function MessageSent() {
  return (
    <div>
        <div className='p-6'>
<div className='bg-[#8A3FFC] w-[270px] h-[62px] p-4 rounded-t-[20px] rounded-l-lg ro '>
    <h3 className='text-[#fff] '>Hi Dr, How are you sdoing?</h3>
</div>
<div className='flex pl-30'>
    <h4 className='topographyText text-[15px] font-[400] text-[#596780]'>Jun 7, 2024, 12:35Am</h4>
</div>
        </div>
    </div>
  )
}
