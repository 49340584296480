import React, { useState, useEffect, useMemo } from "react";
import "../../../assests/styles/DeliveryDateTime.css";

type DeliveryPreferencesProps = {
  setDate: (date: string) => void;
  setTime: (time: string) => void;
  selectedDate: string;
  selectedTime: string;
};

const TimeZoneAndDate: React.FC<DeliveryPreferencesProps> = ({
  setDate,
  setTime,
  selectedDate,
  selectedTime
}) => {
  console.log({selectedDate})
  const [selectedday, selectedmonth, selectedyear] = selectedDate
    ? selectedDate.split("-")
    : ["", "", ""];
    const currentDate = useMemo(() => new Date(), []);
    const defaultBookingTime = new Date(
      currentDate.getTime() + 24 * 60 * 60 * 1000
    ); 
    const defaultMonth = defaultBookingTime.toLocaleString("default", {
      month: "long",
    });
    const [selectedhour, selectedminutes, selectedperiod] =selectedTime? selectedTime.split(/:| /):'';
    console.log("selectedminutes",selectedminutes)
  const [month, setMonth] = useState<string|any>(selectedmonth || defaultMonth);
  const [day, setDay] = useState<number | string>(selectedday || defaultBookingTime.getDate());
  const [year, setYear] = useState<number | string>(selectedyear || defaultBookingTime.getFullYear());
  const [hour, setHour] = useState<number | string>(selectedhour ||defaultBookingTime.getHours() % 12 || 12);
  const [minute, setMinute] = useState<number | string>(selectedminutes?selectedminutes :defaultBookingTime.getMinutes() );
  const [period, setPeriod] = useState<string>(selectedperiod ||defaultBookingTime.getHours() >= 12 ? "PM" : "AM");

  

  useEffect(() => {
    // If the user hasn't set anything yet, set the default booking time to 24 hours ahead
    if (!selectedDate) {
      const defaultBookingTime = new Date(
        currentDate.getTime() + 24 * 60 * 60 * 1000
      ); // 24 hours ahead
      const defaultMonth = defaultBookingTime.toLocaleString("default", {
        month: "long",
      });
      const defaultDay = defaultBookingTime.getDate();
      const defaultYear = defaultBookingTime.getFullYear();
      const defaultHour = defaultBookingTime.getHours() % 12 || 12;
      const defaultMinute = defaultBookingTime.getMinutes();
      const defaultPeriod = defaultBookingTime.getHours() >= 12 ? "PM" : "AM";

      // setMonth(defaultMonth);
      // setDay(defaultDay);
      // setYear(defaultYear);
      // setHour(defaultHour);
      // setMinute(defaultMinute);
      // setPeriod(defaultPeriod);
    }
  }, [currentDate, selectedDate]);

  useEffect(() => {
    
      setDate(`${day}-${month}-${year}`);
      setTime(`${hour}:${minute.toString().padStart(2, "0")} ${period}`);
    
  }, [day, month, year, hour, minute, period, setDate, setTime]);

  const handleDateChange = (setter: React.Dispatch<React.SetStateAction<any>>, value: any) => {
    setter(value);
  };

  const handleTimeChange = (setter: React.Dispatch<React.SetStateAction<any>>, value: any) => {
    setter(value);
  };

  const generateDays = (month: string, year: number) => {
    const daysInMonth = new Date(year, new Date(`${month} 1, ${year}`).getMonth() + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
  ];

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  const days = month && year ? generateDays(month, Number(year)) : [];

  // Filter out past days
  const availableDays = days.filter((d) => {
    const selectedDate = new Date(`${month} ${d}, ${year}`);
    const today = new Date(currentDate.setHours(0, 0, 0, 0));
    return selectedDate >= today;
  });

  // Filter out past months if the current year is selected
  const availableMonths = year && Number(year) === currentDate.getFullYear()
    ? months.slice(currentDate.getMonth())
    : months;

  const disableInvalidTimeSlots = (h: number, m: number, p: string) => {
    if (!day || !month || !year) return false; // Don't disable if date isn't selected
    const selectedTime = new Date(`${month} ${day}, ${year} ${h}:${m} ${p}`);
    const minimumBookingTime = new Date(currentDate.getTime() + 2 * 60 * 60 * 1000); // 2 hours ahead
    return selectedTime < minimumBookingTime;
  };

  return (
    <div className="delivery-date-time">
      <h3>Preferred Delivery Date:</h3>
      <div className="date-selectors section">
        <div className="input-group">
          <label htmlFor="month">Month</label>
          <select
            id="month"
            value={month}
            onChange={(e) => handleDateChange(setMonth, e.target.value)}
          >
            <option value="">Month</option>
            {availableMonths.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="day">Day</label>
          <select
            id="day"
            value={day}
            onChange={(e) => handleDateChange(setDay, Number(e.target.value))}
          >
            <option value="">Day</option>
            {availableDays.map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="year">Year</label>
          <select
            id="year"
            value={year}
            onChange={(e) => handleDateChange(setYear, Number(e.target.value))}
          >
            <option value="">Year</option>
            {Array.from({ length: 10 }, (_, i) => currentDate.getFullYear() + i).map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <h3>Preferred Delivery time:</h3>
      <div className="time-selectors section">
        <div className="input-group">
          <label htmlFor="hour">Hour</label>
          <select
            id="hour"
            value={hour}
            onChange={(e) => handleTimeChange(setHour, Number(e.target.value))}
          >
            <option value="">Hour</option>
            {hours.map((h) => (
              <option
                key={h}
                value={h}
                disabled={disableInvalidTimeSlots(h, Number(minute), period)}
              >
                {h}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="minute">Minute</label>
          <select
            id="minute"
            value={minute}
            onChange={(e) => handleTimeChange(setMinute, Number(e.target.value))}
          >
            <option value="">Minute</option>
            {minutes.map((m) => (
              <option
                key={m}
                value={m}
                disabled={disableInvalidTimeSlots(Number(hour), m, period)}
              >
                {m.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="period">AM/PM</label>
          <select
            id="period"
            value={period}
            onChange={(e) => handleTimeChange(setPeriod, e.target.value)}
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TimeZoneAndDate;
