import React, { useState } from 'react';
import Footer from "layout/patient/footer";
import Header from "layout/patient/header";
import AddAccountsMobile from "views/patients/pages/auth/AddAccountsMobile";

import MobileHeader from 'layout/patient/MobileHeader';

const defaultProfilePicture = '/default-profile-picture.png'; // Replace with your default profile picture URL

const AddAccounts: React.FC = () => {
    const [fullName, setFullName] = useState('Your Name');
    const [email, setEmail] = useState('email@example.com');
    const [phoneNumber, setPhoneNumber] = useState('1234567890');
    const [country, setCountry] = useState('Country Name');
    const [district, setDistrict] = useState('District Name');
    const [village, setVillage] = useState('Village Name');
    const [dob, setDob] = useState('01-01-2000');
    const [profilePicture, setProfilePicture] = useState<string>(defaultProfilePicture); // Initialize with default profile picture

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfilePicture(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSidebarOpen = () => {
        setIsSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setIsSidebarOpen(false);
    };

    const handleLogout = () => {
        console.log('Logged out');
        // Add actual logout logic here
    };

    return (
        <div>
            <Header />
            
            <div className="block md:hidden">
                <AddAccountsMobile />
                <MobileHeader onOpenSidebar={handleSidebarOpen} onLogout={handleLogout} />
            </div>
            <div className="hidden md:flex" style={{ marginTop: '4px',marginRight: '10%', whiteSpace: 'nowrap',marginLeft: '10%' }}>
                <div className="w-1/4 mr-8">
                    <h2 className="text-xl font-bold mb-4">Add Account Setting</h2>
                    <span style={{
                        fontFamily: 'Poppins',
                        fontSize: '15px',
                        fontWeight: '400',
                        color: '#8A3FFC',
                        background: '#F7F2FF',
                        width: '100%',
                        minHeight: '50px',
                        padding: '10px',
                        display: 'inline-block',
                        marginBottom: '8px'
                    }} className="mr-2">
                        Profile
                    </span>
                    <div className="relative h-full bg-gray-400 w-1" style={{ position: 'absolute', top: '0', right: '0' }}></div>
                    <p className="mt-4 text-blue-500 cursor-pointer">
                        <a href="/change-password" className="hover:text-blue-700 hover:underline">Change my password</a>
                    </p>
                </div>
                <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                    <div className="p-8">
                        <div className="text-xl font-bold mb-4">Profile Picture</div>
                        <img src={profilePicture} alt="Profile" className="w-24 h-24 rounded-full mx-auto mb-4" />
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="mt-2"
                        />
                    </div>
                    <div className="p-8">
                        <div className="text-xl font-bold mb-4">Full Name</div>
                        <input
                            type="text"
                            className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Full Name"
                        />
                    </div>
                    <div className="p-8 border-t border-gray-200">
                        <div className="text-xl font-bold mb-4">Contact Information</div>
                        <div className="mb-4">
                            <div className="text-lg font-bold mb-2">Email Address</div>
                            <input
                                type="email"
                                className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email Address"
                            />
                        </div>
                        <div className="mb-4">
                            <div className="text-lg font-bold mb-2">Phone Number</div>
                            <input
                                type="tel"
                                className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="Phone Number"
                            />
                        </div>
                    </div>
                    <div className="p-8 border-t border-gray-200">
                        <div className="text-xl font-bold mb-4">Address</div>
                        <div className="mb-4">
                            <div className="text-lg font-bold mb-2">Country</div>
                            <input
                                type="text"
                                className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                placeholder="Country"
                            />
                        </div>
                        <div className="mb-4">
                            <div className="text-lg font-bold mb-2">District</div>
                            <input
                                type="text"
                                className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                                value={district}
                                onChange={(e) => setDistrict(e.target.value)}
                                placeholder="District"
                            />
                        </div>
                        <div className="mb-4">
                            <div className="text-lg font-bold mb-2">Village</div>
                            <input
                                type="text"
                                className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                                value={village}
                                onChange={(e) => setVillage(e.target.value)}
                                placeholder="Village"
                            />
                        </div>
                    </div>
                    <div className="p-8 border-t border-gray-200">
                        <div className="text-xl font-bold mb-4">Date of Birth</div>
                        <input
                            type="text"
                            className="border-gray-300 border-2 rounded-md px-3 py-2 w-full"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                            placeholder="Date of Birth"
                        />
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

export default AddAccounts;
