import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import downloadIcon from '../../../pages/auth/MyDocuments/download-icon.png';
import deleteIcon from '../../../pages/auth/MyDocuments/delete-icon.png';
import threeDotsIcon from '../../../pages/auth/MyDocuments/three-dots-icon.png';
import addIcon from '../../../pages/auth/MyDocuments/add-icon.png';
import Footer from 'layout/patient/footer';
import MobileHeader from 'layout/patient/MobileHeader';
import MobileMyDocument from 'views/patients/pages/auth/MobileMyDocument';
import SearchComponent from 'components/shared/SearchComponent';


const MyDocument: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [searchResults, setSearchResults] = useState<any[]>([]); // Replace 'any' with your actual result type
    const [searchError, setSearchError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSearch = async (query: string) => {
        if (query.trim() === '') {
            setSearchError('Please enter a search term.');
            setSearchResults([]);
            return;
        }

        setLoading(true);
        setSearchError(null);

        try {
            // Replace '/api/search' with your actual search API endpoint
            const response = await fetch(`/api/search?query=${encodeURIComponent(query)}`);
            const data = await response.json();

            if (response.ok) {
                if (data.results && data.results.length > 0) {
                    setSearchResults(data.results);
                    navigate('/search', { state: { results: data.results } });
                } else {
                    setSearchResults([]);
                    setSearchError('No results found.');
                }
            } else {
                setSearchError(data.message || 'An error occurred while searching.');
            }
        } catch (error) {
            console.error('Search error:', error);
            setSearchError('An error occurred while searching.');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadClick = () => {
        navigate('/download');
    };

    const handleDeleteClick = () => {
        navigate('/delete');
    };

    const handleAddClick = () => {
        document.getElementById('fileInput')?.click();
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log('File selected:', file.name);
        }
    };

    const handleSidebarOpen = () => {
        setIsSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setIsSidebarOpen(false);
    };

    const handleLogout = () => {
        console.log('Logged out');
        // Add actual logout logic here
    };

    return (
        <div className="flex justify-center" style={{ marginRight: '10%', whiteSpace: 'nowrap', marginLeft: '-2%' }}>
            <div className="w-full max-w-[80%] mx-auto ml-[16%]">
                <MobileMyDocument />
                <MobileHeader onOpenSidebar={handleSidebarOpen} onLogout={handleLogout} />
                <div className="hidden md:block">
                    <SearchComponent 
                        onSearch={handleSearch}
                        searchResults={searchResults}
                        searchError={searchError}
                        loading={loading}
                    />
                    {/* Checkbox Section */}
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                            <input type="checkbox" className="w-[15px] h-[15px]" />
                            <span className="font-poppins font-normal text-[15px] text-black ml-2">Select all</span>
                        </div>
                        <div className="flex items-center">
                            <img
                                src={downloadIcon}
                                alt="Download"
                                className="w-[20px] h-[20px] cursor-pointer"
                                onClick={handleDownloadClick}
                            />
                            <img
                                src={deleteIcon}
                                alt="Delete"
                                className="w-[20px] h-[20px] cursor-pointer ml-2"
                                onClick={handleDeleteClick}
                            />
                        </div>
                    </div>
                    {/* Document Section */}
                    <div className="mb-4">
                        <div className="flex items-center justify-between mb-2">
                            <input type="checkbox" className="w-[15px] h-[15px]" />
                            <div className="ml-2">
                                <div className="font-poppins font-semibold text-[15px] text-[#0A0A0B]">HIV</div>
                                <div className="font-poppins font-normal text-[15px] text-black">Size: 23KB</div>
                            </div>
                            <div className="flex justify-between items-center flex-grow space-x-8">
                                <div className="flex flex-col items-start ml-[16%]">
                                    <div className="font-poppins font-normal text-[15px] text-black">Document Type</div>
                                    <div className="font-poppins font-semibold text-[15px] text-[#0A0A0B]">Lab Results</div>
                                </div>
                                <div className="text-center">
                                    <div className="font-poppins font-normal text-[15px] text-black">Shared by:</div>
                                    <div className="font-poppins font-normal text-[15px] text-black">Shared on:</div>
                                </div>
                                <div>
                                    <div className="font-poppins font-semibold text-[15px] text-[#0A0A0B]">XXX Medical Center</div>
                                    <div className="font-poppins font-semibold text-[15px] text-[#0A0A0B]">Jun 25, 2025</div>
                                </div>
                                <img
                                    src={threeDotsIcon}
                                    alt="Options"
                                    className="w-[15px] h-[2.67px] cursor-pointer"
                                />
                            </div>
                        </div>
                        <hr className="border-t border-[#D9D9D9]" />
                    </div>
                    {/* Add Button */}
                    <div className="absolute bottom-4 right-4">
                        <img
                            src={addIcon}
                            alt="Add"
                            className="w-[75px] h-[75px] cursor-pointer"
                            onClick={handleAddClick}
                        />
                    </div>
                    <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        onChange={handleFileUpload}
                    />
                </div>
                {/* <Footer /> */}
            </div>
        </div>
    );
};

export default MyDocument;
