import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HivCareStateProps } from "./Types";
import { createHivCareService } from "./Hivcare.actions";
type PreExposureProphylaxisPayload = {
  type: keyof typeof initialState.Pre_Exposure_Prophylaxis | any;
  value: string[] | string | null | number;
};
type PostExposureProphylaxisPayload = {
  type: keyof typeof initialState.Post_Exposure_Prophylaxis | any;
  value: string[] | string | null | number;
};

type AntiretroviralTherapyPayload = {
  type: keyof typeof initialState.Antiretroviral_Therapy | any;
  value: string[] | string | null | number;
};

type MentalHealthServicesPayload = {
  type: keyof typeof initialState.Mental_Health_Services | any;
  value: string[] | string | null | number;
};

type HealthProductsPayload = {
  type: keyof typeof initialState.Health_Products | any;
  value: string[] | string | null | number;
};
const initialState: HivCareStateProps = {
  Pre_Exposure_Prophylaxis: {
    related_symptoms: null,
    hiv_status: null,
    healthIssues: null,
    last_test_date: null,
    medications: null,
    health_conditions: null,
    prep_before: null,
    medication_taken: null,
    medication_length: null,
    prefered_prep_medication: null,
    facility_id: null,
    sample_collection_location: null,
    result_delivery_method: null,
    preferred_date: null,
    preferred_time: null,
    adherence_history: null,
    report_file_uri: null,
  },
  Post_Exposure_Prophylaxis: {
    related_symptoms: null,
    hiv_status: null,
    last_test_date: null,
    report_file_uri: null,
    health_issues: null,
    medications: null,
    health_conditions: null,
    pregnancy_status: null,
    exposer_date: null,
    exposer_types: null,
    exposer_details: null,
    prep_before: null,
    medication_taken: null,
    medication_length: null,
    reason_for_pep: null,
    side_effects: null,
    prefered_prep_medication: null,
    facility_id: null,
    sample_collection_location: null,
    result_delivery_method: null,
    preferred_date: null,
    preferred_time: null,
    facility_rel_status: null,
    additional_information: null,
  },
  Antiretroviral_Therapy: {
    cd4_count: null,
    viral_load: null,
    health_issues: null,
    taking_art: null,
    other_medications: null,
    recent_report_file_uri: null,
    past_art_prev_art_regiment: null,
    past_art_art_status: null,
    past_art_adherence_history: null,
    prefered_art_regiment: null,
    prefered_dosage_duration: null,
    delivery_method: null,
    facility_rel_status: null,
    additional_information: null,
    facility_id: null,
    sample_collection_location: null,
    result_delivery_method: null,
    preferred_date: null,
    preferred_time: null,
  },
  Mental_Health_Services: {
    mental_health_issues: null,
    mental_experienced_symptoms: null,
    prev_mental_treatment: null,
    treatment_received: null,
    facility_id: null,
    sample_collection_location: null,
    result_delivery_method: null,
    preferred_date: null,
    preferred_time: null,
    facility_rel_status: null,
    additional_information: null,
    medication_lists: null,
    side_effects: null,
    mental_health_condition: null,
    current_taking_medication: null,
    medications: null,
    mental_health_services: null,
    health_conditions: null,
    health_issues: null,
  },
  Health_Products: {
    health_issues: null,
    medications: null,
    health_conditions: null,
    products_needed: null,
    quantity_required_for_condoms: null,
    quantity_required_for_lubricants: null,
    quantity_required_for_clean_needles: null,
    quantity_required_for_sti_treatment: null,
    quantity_required_for_self_test_kits: null,
    quantity_required_for_emergency_concentraptive: null,
    quantity_required_for_pain_relief_medication: null,
    prefered_brand_type_for_condoms: null,
    prefered_brand_type_for_lubricants: null,
    prefered_brand_type_for_clean_needles: null,
    prefered_brand_type_for_sti_treatment: null,
    prefered_brand_type_for_self_test_kits: null,
    prefered_brand_type_for_emergency_concentraptive: null,
    prefered_brand_type_for_pain_relief_medication: null,
    usage_experience_products_usage: null,
    usage_experience_which_product: null,
    usage_experience_product_side_effect: null,
    facility_id: null,
    result_delivery_method: null,
    preferred_date: null,
    preferred_time: null,
    additional_information: null,
  },
  errors: null,
  createErrors: null,
  createLoadings: false,
  createSuccess: false,
  edit_from_summary: false,
  redirect_to_service: false
};

const HivCareSlice = createSlice({
  name: "HivCareSlice",
  initialState,
  reducers: {
    setPreExposureProphylaxis: (
      state,
      { payload }: PayloadAction<PreExposureProphylaxisPayload>
    ) => {
      if (state.Pre_Exposure_Prophylaxis) {
        if (state.Pre_Exposure_Prophylaxis) {
          switch (payload.type) {
            case "related_symptoms":
              state.Pre_Exposure_Prophylaxis.related_symptoms =
                payload.value as string[];
              break;
            case "hiv_status":
              state.Pre_Exposure_Prophylaxis.hiv_status =
                payload.value as string[];
              break;
            case "medications":
              state.Pre_Exposure_Prophylaxis.medications =
                payload.value as string[];
              break;
            case "adherence_history":
              state.Pre_Exposure_Prophylaxis.adherence_history =
                payload.value as string;
              break;
            case "healthIssues":
              state.Pre_Exposure_Prophylaxis.healthIssues =
                payload.value as string[];
              break;
            case "health_conditions":
              state.Pre_Exposure_Prophylaxis.health_conditions =
                payload.value as any;
              break;
            case "prep_before":
              state.Pre_Exposure_Prophylaxis.prep_before =
                payload.value as string[];
              break;
            case "medication_taken":
              state.Pre_Exposure_Prophylaxis.medication_taken =
                payload.value as string[];
              break;
            case "last_test_date":
              state.Pre_Exposure_Prophylaxis.last_test_date =
                payload.value as string;
              break;
            case "medication_length":
              state.Pre_Exposure_Prophylaxis.medication_length =
                payload.value as string[];
              break;
            case "prefered_prep_medication":
              state.Pre_Exposure_Prophylaxis.prefered_prep_medication =
                payload.value as string[];
              break;
            case "facility_id":
              state.Pre_Exposure_Prophylaxis.facility_id =
                payload.value as number;
              break;
            case "sample_collection_location":
              state.Pre_Exposure_Prophylaxis.sample_collection_location =
                payload.value as string;
              break;
            case "result_delivery_method":
              state.Pre_Exposure_Prophylaxis.result_delivery_method =
                payload.value as string;
              break;
            case "preferred_date":
              state.Pre_Exposure_Prophylaxis.preferred_date =
                payload.value as string;
              break;
            case "preferred_time":
              state.Pre_Exposure_Prophylaxis.preferred_time =
                payload.value as string;
              break;
              case "report_file_uri":
            state.Pre_Exposure_Prophylaxis.report_file_uri =
              payload.value as string;
            break;
            default:
              break;
          }
        }
      }
    },
    setPostExposureProphylaxis: (
      state,
      { payload }: PayloadAction<PostExposureProphylaxisPayload>
    ) => {
      if (state.Post_Exposure_Prophylaxis) {
        switch (payload.type) {
          case "related_symptoms":
            state.Post_Exposure_Prophylaxis.related_symptoms =
              payload.value as string[];
            break;
          case "hiv_status":
            state.Post_Exposure_Prophylaxis.hiv_status =
              payload.value as string;
            break;
          case "last_test_date":
            state.Post_Exposure_Prophylaxis.last_test_date =
              payload.value as string;
            break;
          case "report_file_uri":
            state.Post_Exposure_Prophylaxis.report_file_uri =
              payload.value as string;
            break;
          case "health_issues":
            state.Post_Exposure_Prophylaxis.health_issues =
              payload.value as string[];
            break;
          case "medications":
            state.Post_Exposure_Prophylaxis.medications =
              payload.value as string[];
            break;
          case "health_conditions":
            state.Post_Exposure_Prophylaxis.health_conditions =
              payload.value as string[];
            break;
          case "pregnancy_status":
            state.Post_Exposure_Prophylaxis.pregnancy_status =
              payload.value as string[];
            break;
          case "exposer_date":
            state.Post_Exposure_Prophylaxis.exposer_date =
              payload.value as string;
            break;
          case "exposer_types":
            state.Post_Exposure_Prophylaxis.exposer_types =
              payload.value as string;
            break;
          case "exposer_details":
            state.Post_Exposure_Prophylaxis.exposer_details =
              payload.value as string;
            break;
          case "prep_before":
            state.Post_Exposure_Prophylaxis.prep_before =
              payload.value as string[];
            break;
          case "medication_taken":
            state.Post_Exposure_Prophylaxis.medication_taken =
              payload.value as string[];
            break;
          case "medication_length":
            state.Post_Exposure_Prophylaxis.medication_length =
              payload.value as string;
            break;
          case "reason_for_pep":
            state.Post_Exposure_Prophylaxis.reason_for_pep =
              payload.value as string[];
            break;
          case "side_effects":
            state.Post_Exposure_Prophylaxis.side_effects =
              payload.value as string[];
            break;
          case "prefered_prep_medication":
            state.Post_Exposure_Prophylaxis.prefered_prep_medication =
              payload.value as string[];
            break;
          case "facility_id":
            state.Post_Exposure_Prophylaxis.facility_id =
              payload.value as number;
            break;
          case "sample_collection_location":
            state.Post_Exposure_Prophylaxis.sample_collection_location =
              payload.value as string;
            break;
          case "result_delivery_method":
            state.Post_Exposure_Prophylaxis.result_delivery_method =
              payload.value as string[];
            break;
          case "preferred_date":
            state.Post_Exposure_Prophylaxis.preferred_date =
              payload.value as string;
            break;
          case "preferred_time":
            state.Post_Exposure_Prophylaxis.preferred_time =
              payload.value as string;
            break;
          case "facility_rel_status":
            state.Post_Exposure_Prophylaxis.facility_rel_status =
              payload.value as string;
            break;
          case "additional_information":
            state.Post_Exposure_Prophylaxis.additional_information =
              payload.value as string;
            break;
          default:
            break;
        }
      }
    },

    setAntiretroviralTherapy: (
      state,
      { payload }: PayloadAction<AntiretroviralTherapyPayload>
    ) => {
      if (state.Antiretroviral_Therapy) {
        switch (payload.type) {
          case "cd4_count":
            state.Antiretroviral_Therapy.cd4_count = payload.value as string;
            break;
          case "viral_load":
            state.Antiretroviral_Therapy.viral_load = payload.value as string;
            break;
          case "health_issues":
            state.Antiretroviral_Therapy.health_issues =
              payload.value as string;
            break;
          case "taking_art":
            state.Antiretroviral_Therapy.taking_art = payload.value as string;
            break;
          case "other_medications":
            state.Antiretroviral_Therapy.other_medications =
              payload.value as string;
            break;
          case "recent_report_file_uri":
            state.Antiretroviral_Therapy.recent_report_file_uri =
              payload.value as any;
            break;
          case "past_art_prev_art_regiment":
            state.Antiretroviral_Therapy.past_art_prev_art_regiment =
              payload.value as string;
            break;
          case "past_art_art_status":
            state.Antiretroviral_Therapy.past_art_art_status =
              payload.value as string;
            break;
          case "past_art_adherence_history":
            state.Antiretroviral_Therapy.past_art_adherence_history =
              payload.value as string;
            break;
          case "prefered_art_regiment":
            state.Antiretroviral_Therapy.prefered_art_regiment =
              payload.value as string;
            break;
          case "prefered_dosage_duration":
            state.Antiretroviral_Therapy.prefered_dosage_duration =
              payload.value as string;
            break;
          case "delivery_method":
            state.Antiretroviral_Therapy.delivery_method =
              payload.value as string;
            break;
          case "facility_rel_status":
            state.Antiretroviral_Therapy.facility_rel_status =
              payload.value as string;
            break;
          case "additional_information":
            state.Antiretroviral_Therapy.additional_information =
              payload.value as string;
            break;
          case "facility_id":
            state.Antiretroviral_Therapy.facility_id = payload.value as any;
            break;
          case "sample_collection_location":
            state.Antiretroviral_Therapy.sample_collection_location =
              payload.value as string;
            break;
          case "result_delivery_method":
            state.Antiretroviral_Therapy.result_delivery_method =
              payload.value as string;
            break;
          case "preferred_date":
            state.Antiretroviral_Therapy.preferred_date =
              payload.value as string;
            break;
          case "preferred_time":
            state.Antiretroviral_Therapy.preferred_time =
              payload.value as string;
            break;
          case "past_art_side_eefects":
            state.Antiretroviral_Therapy.past_art_side_eefects =
              payload.value as string;
            break;
          default:
            break;
        }
      }
    },

    setMentalHealthServices: (
      state,
      { payload }: PayloadAction<MentalHealthServicesPayload>
    ) => {
      if (state.Mental_Health_Services) {
        switch (payload.type) {
          case "mental_health_issues":
            state.Mental_Health_Services.mental_health_issues =
              payload.value as string;
            break;
          case "mental_experienced_symptoms":
            state.Mental_Health_Services.mental_experienced_symptoms =
              payload.value as string;
            break;
          case "prev_mental_treatment":
            state.Mental_Health_Services.prev_mental_treatment =
              payload.value as string;
            break;
          case "treatment_received":
            state.Mental_Health_Services.treatment_received =
              payload.value as string;
            break;
          case "facility_id":
            state.Mental_Health_Services.facility_id = payload.value as string;
            break;
          case "sample_collection_location":
            state.Mental_Health_Services.sample_collection_location =
              payload.value as string;
            break;
          case "result_delivery_method":
            state.Mental_Health_Services.result_delivery_method =
              payload.value as string;
            break;
          case "preferred_date":
            state.Mental_Health_Services.preferred_date =
              payload.value as string;
            break;
          case "preferred_time":
            state.Mental_Health_Services.preferred_time =
              payload.value as string;
            break;
          case "facility_rel_status":
            state.Mental_Health_Services.facility_rel_status =
              payload.value as string;
            break;
          case "additional_information":
            state.Mental_Health_Services.additional_information =
              payload.value as string;
            break;
          case "medication_lists":
            state.Mental_Health_Services.medication_lists =
              payload.value as string;
            break;
          case "side_effects":
            state.Mental_Health_Services.side_effects = payload.value as string;
            break;
          case "mental_health_condition":
            state.Mental_Health_Services.mental_health_condition =
              payload.value as string;
            break;
          case "current_taking_medication":
            state.Mental_Health_Services.current_taking_medication =
              payload.value as string;
            break;
          case "medications":
            state.Mental_Health_Services.medications = payload.value as string;
            break;
          case "mental_health_services":
            state.Mental_Health_Services.mental_health_services =
              payload.value as string;
            break;
          case "health_conditions":
            state.Mental_Health_Services.health_conditions =
              payload.value as string;
            break;
          case "health_issues":
            state.Mental_Health_Services.health_issues =
              payload.value as string;
            break;
          default:
            break;
        }
      }
    },

    setHealthProducts: (
      state,
      { payload }: PayloadAction<HealthProductsPayload>
    ) => {
      if (state.Health_Products) {
        switch (payload.type) {
          case "health_issues":
            state.Health_Products.health_issues = payload.value as string;
            break;
          case "medications":
            state.Health_Products.medications = payload.value as string;
            break;
          case "health_conditions":
            state.Health_Products.health_conditions = payload.value as string;
            break;
          case "products_needed":
            state.Health_Products.products_needed = payload.value as string;
            break;
          case "quantity_required_for_condoms":
            state.Health_Products.quantity_required_for_condoms =
              payload.value as string;
            break;
          case "quantity_required_for_lubricants":
            state.Health_Products.quantity_required_for_lubricants =
              payload.value as string;
            break;
          case "quantity_required_for_clean_needles":
            state.Health_Products.quantity_required_for_clean_needles =
              payload.value as string;
            break;
          case "quantity_required_for_sti_treatment":
            state.Health_Products.quantity_required_for_sti_treatment =
              payload.value as string;
            break;
          case "quantity_required_for_self_test_kits":
            state.Health_Products.quantity_required_for_self_test_kits =
              payload.value as string;
            break;
          case "quantity_required_for_emergency_concentraptive":
            state.Health_Products.quantity_required_for_emergency_concentraptive =
              payload.value as string;
            break;
          case "quantity_required_for_pain_relief_medication":
            state.Health_Products.quantity_required_for_pain_relief_medication =
              payload.value as string;
            break;
          case "prefered_brand_type_for_condoms":
            state.Health_Products.prefered_brand_type_for_condoms =
              payload.value as string;
            break;
          case "prefered_brand_type_for_lubricants":
            state.Health_Products.prefered_brand_type_for_lubricants =
              payload.value as string;
            break;
          case "prefered_brand_type_for_clean_needles":
            state.Health_Products.prefered_brand_type_for_clean_needles =
              payload.value as string;
            break;
          case "prefered_brand_type_for_sti_treatment":
            state.Health_Products.prefered_brand_type_for_sti_treatment =
              payload.value as string;
            break;
          case "prefered_brand_type_for_self_test_kits":
            state.Health_Products.prefered_brand_type_for_self_test_kits =
              payload.value as string;
            break;
          case "prefered_brand_type_for_emergency_concentraptive":
            state.Health_Products.prefered_brand_type_for_emergency_concentraptive =
              payload.value as string;
            break;
          case "prefered_brand_type_for_pain_relief_medication":
            state.Health_Products.prefered_brand_type_for_pain_relief_medication =
              payload.value as string;
            break;
          case "usage_experience_products_usage":
            state.Health_Products.usage_experience_products_usage =
              payload.value as string;
            break;
          case "usage_experience_which_product":
            state.Health_Products.usage_experience_which_product =
              payload.value as string;
            break;
          case "usage_experience_product_side_effect":
            state.Health_Products.usage_experience_product_side_effect =
              payload.value as string;
            break;
          case "facility_id":
            state.Health_Products.facility_id = payload.value as number;
            break;
          case "result_delivery_method":
            state.Health_Products.result_delivery_method = payload.value as string;
            break;
          case "preferred_date":
            state.Health_Products.preferred_date = payload.value as string;
            break;
          case "preferred_time":
            state.Health_Products.preferred_time = payload.value as string;
            break;
          case "additional_information":
            state.Health_Products.additional_information =
              payload.value as string;
            break;

          default:
            return;
        }
      }
    },
    setErrors: (state, payload) => {

      state.errors = payload.payload as []

    },
    setCreateSuccess: (state, payload) => {
      state.createSuccess = payload.payload;
    },
    setEditSummary: (state, payload) => {
      // @ts-ignore
      state.edit_from_summary = payload.payload;
    },

    setRedirectService: (state, payload) => {
      // @ts-ignore
      state.redirect_to_service = payload.payload;
    },
    resetHivCareState: (state) => {
      return {...initialState}; 
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createHivCareService.pending, (state) => {
      state.createLoadings = true;
      state.createSuccess = false;
    });
    builder.addCase(createHivCareService.fulfilled, (state, action) => {
      state.createLoadings = false;
      state.errors = null;
      state.createSuccess = true;
      


    });
    builder.addCase(createHivCareService.rejected, (state, action) => {

      state.createLoadings = false;
      state.createSuccess = false;



    });
  },
});

export const {
  setPreExposureProphylaxis,
  setErrors,
  setAntiretroviralTherapy,
  setHealthProducts,
  setMentalHealthServices,
  setPostExposureProphylaxis,
  setCreateSuccess,
  setEditSummary,
  setRedirectService,
  resetHivCareState
} = HivCareSlice.actions;
export default HivCareSlice.reducer;