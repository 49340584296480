//import React from "react";
import { useSelector, useDispatch } from "react-redux";
//import { setIsAllowed } from "Features/auth/auth.slice";
export default function useStates() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.auth.userInfo);
  const isAllowed = useSelector((state: any) => state.auth.isAllowed);
  const patientLabForm = useSelector((state: any) => state.requestLabService);
  const {
    email,
    first_name,
    last_name,
    username,
    id,
    country,
    district,
    phone_number,
    sub_county,
    gender,
    whatsap_number,
    village,
    email_verified_at,
    phone_verified_at,
  } = userInfo;
  function isUserInfoCompleted(): boolean {
    const {
      email,
      first_name,
      last_name,
      // username,
      // id,
      // country,
      // district,
      phone_number,
     // sub_county,
      gender,
      // whatsap_number,
      // village,
    
    } = userInfo;
    return (
      email !== null &&
      email !== "" &&
      first_name !== null &&
      first_name !== "" &&
      last_name !== null &&
      last_name !== "" &&
   //   username !== null &&
   //   username !== "" &&
    //  id !== null &&
     // id !== "" &&
    //  country !== null &&
     // country !== "" &&
     // district !== null &&
     // district !== "" &&
      phone_number !== null &&
      phone_number !== "" &&
     // sub_county !== null &&
     // sub_county !== "" &&
      gender !== null &&
      gender !== ""
      // &&
      // whatsap_number !== null &&
      // whatsap_number !== "" &&
    //  village !== null &&
     // village !== ""
    );
  }

  const {
    patientServiceFormStatus,
    getFacilities_data,
    create_loading,
    create_error,
  } = patientLabForm;
  return {
    email,
    first_name,
    last_name,
    username,
    id,
    country,
    district,
    sub_county,
    phone_number,
    gender,
    whatsap_number,
    patientServiceFormStatus,
    getFacilities_data,
    village,
    create_loading,

    create_error,
    email_verified_at,
    isUserInfoCompleted,
    isAllowed,
    phone_verified_at
  };
}
