import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
type BirthDay = {
  handleChange: (value: string) => void;
};

const BirthdayForm: React.FC<BirthDay> = ({ handleChange }) => {
  const dateOfbirth = useSelector(
    (state: any) => state.auth.userInfo.date_of_birth


  );

  const [selectedyear, selectedmonth, selectedday] = dateOfbirth ? dateOfbirth.split('-') : '';
  const [day, setDay] = useState<number | string>(selectedday ? selectedday : '');
  const [month, setMonth] = useState<any>(selectedmonth ? selectedmonth : '');
  const [year, setYear] = useState<number | string>(selectedyear ? selectedyear : '');
  const [error, setError] = useState<string | null>(null);
  const [monthDay, setMonthDay] = useState<number>();

  const months = [
    { value: 1, label: 'January', days: 31 },
    { value: 2, label: 'February', days: 28 },
    { value: 3, label: 'March', days: 31 },
    { value: 4, label: 'April', days: 30 },
    { value: 5, label: 'May', days: 31 },
    { value: 6, label: 'June', days: 30 },
    { value: 7, label: 'July', days: 31 },
    { value: 8, label: 'August', days: 31 },
    { value: 9, label: 'September', days: 30 },
    { value: 10, label: 'October', days: 31 },
    { value: 11, label: 'November', days: 30 },
    { value: 12, label: 'December', days: 31 },
  ];

  const updateParent = () => {
    const formattedDate = `${year}-${month}-${day}`;
    handleChange(formattedDate);
    console.log(formattedDate);
  };

  useEffect(() => {
    if (year && year.toString().length === 4) {
      const currentYear = new Date().getFullYear();
      const age = currentYear - Number(year);
      if (age >= 15) {
        setError(null);
      }
    }
    updateParent();
  }, [day, year, month]);
  console.log(monthDay)
  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // if (value > monthDay) {
    //   return;
    // }
    setDay(value);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const label = e.target.value;
    setMonth(label);
    const filteredMonth = months.filter((month) => month.label === label);

    setMonthDay(filteredMonth[0].days as number);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const currentYear = new Date().getFullYear();
    const age = currentYear - Number(value);

    if (value === '' || /^[0-9]{0,4}$/.test(value)) {
      if (value.length === 4) {
        if (age < 15) {
          setYear(''); // Clear the year field
          setError('Sorry, you must be 15 years or older to use our platform.');
        } else {
          setYear(value);
          setError(null);
        }
      } else {
        setYear(value);
        setError(null);
      }
    }
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg mx-auto w-full max-w-[545px] lg:w-[144%] lg:max-w-[545px]" style={{ marginLeft: '-2%', paddingRight: '0%', }}>
      <div className="flex flex-wrap gap-6">
        <div className="flex-1 min-w-[120px]">
          <label htmlFor="month" className="block text-gray-700 font-medium mb-4 w-[100%]">
            Month
          </label>
          <select
            id="month"
            className="block w-full p-2 border border-[#d4d7e3] rounded-lg focus:outline-none focus:border-[#8a3ffc] focus:ring-0"

            style={{height: '52px'}}
            value={month}
            onChange={handleMonthChange}
          >
            <option value='' disabled>Month</option>
            {months.map((m) => (
              // @ts-ignore
              <option key={m.value} value={m.label}>
                {m.label}
              </option>
            ))}
          </select>
        </div>

        <div className="flex-1 min-w-[120px]">
          <label htmlFor="day" className="block text-gray-700 font-medium mb-4">
            Day
          </label>
          <input
            type="text"
            id="day"
            className="block w-full p-2 border border-[#d4d7e3] rounded-lg focus:outline-none focus:border-[#8a3ffc] focus:ring-0"

            style={{height: '52px'}}
            value={day}
            onChange={handleDayChange}
            placeholder="DD"
            inputMode="numeric"
            maxLength={2}
          />
        </div>

        <div className="flex-1 min-w-[120px]">
          <label htmlFor="year" className="block text-gray-700 font-medium mb-4">
            Year
          </label>
          <input
            type="text"
            id="year"
            className="block w-full p-2 border border-[#d4d7e3] rounded-lg focus:outline-none focus:border-[#8a3ffc] focus:ring-0"

            style={{height: '52px'}}
            value={year}
            onChange={handleYearChange}
            placeholder="YYYY"
            inputMode="numeric"
            maxLength={4}
          />
        </div>
      </div>
      {error && <p className="text-customRed mt-2">{error}</p>}
    </div>
  );
};

export default BirthdayForm;
