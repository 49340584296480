// import React, { useState } from "react";
// import { useNavigate } from 'react-router-dom';
// import { logo, loginLogo } from "assests/dashBoard";
// import useLoginForm from "hooks/useLoginForm";
// import ValidationTitle from "components/shared/validation";
// import RequestService from "services/requestService";
// import { useDispatch } from 'react-redux';
// import NetlifeRegLogo from "../../../../assests/icons/netlifRegLogo.svg";
// import { setIsAuthenticated, setUserInfo } from '../../../../Features/auth/auth.slice';
// import localStorageService from "services/localStorageService";
// import Loader from "components/shared/Loader";
// import ResetPassword from './ResetPassword'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// export default function Login() {
//   const {
//     handleChange,
//     validateLogin,
//     errors,
//     loginValues,
//     setLoginErrors,
//     setFormSubmitStatus
//   } = useLoginForm();

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
//   const [passwordVisible, setPasswordVisible] = useState(false);

//   const handleLogin = () => {
//     const status = validateLogin();

//     if (status) {
//       submitFormData();
//     }
//   };

//   const setErrorResponse = (errors: { [key: string]: string[] }) => {
//     for (let key in errors) {
//       setLoginErrors(key, errors[key][0]);
//     }
//   };

//   const submitFormData = async () => {
//     setFormSubmitStatus(true);

//     RequestService.postMethod('login', loginValues)
//       .then(async (response) => {
//         const responseData = response.data.responseData;

//         dispatch(setUserInfo(responseData));
//         await localStorageService.setLocalStorageData('user', responseData);

//         setFormSubmitStatus(false);
//         dispatch(setIsAuthenticated(true));

//         navigate('/dashboard');
//       })
//       .catch(error => {
//         setFormSubmitStatus(false);
//         const errorData = error?.response?.data;

//         if (errorData?.responseData) {
//           setLoginErrors('password', errorData?.responseData);
//         }
//         if (errorData?.status && errorData?.errors) {
//           const errors = errorData.errors;
//           setErrorResponse(errors);
//         }
//       });
//   };

//   const redirectToSignPage = (relativePath: string) => {
//     navigate(relativePath);
//   };

//   const openForgotPasswordPopup = () => {
//     setShowForgotPasswordPopup(true);
//   };

//   const closeForgotPasswordPopup = () => {
//     setShowForgotPasswordPopup(false);
//   };

//   const togglePasswordVisibility = () => {
//     setPasswordVisible(!passwordVisible);
//   };

//   return (
//     <div>
//       <div className="registration-container">
//         <div className="registration-form">
//           <h2>We missed you!</h2>
//           <p>
//             Today is a new day. It's your day. Sign in to your account and get health care now.
//           </p>
//           <form>
//             <div>
//               <label>Email</label>
//               <input
//                 type="text"
//                 placeholder="williamfavorx@gmail.com"
//                 value={loginValues.email}
//                 onChange={(e) => handleChange(e)}
//                 name="email"
//               />

//               {errors.email && <ValidationTitle title={errors.email} />}
//             </div>

//             <div>
//               <label>Password</label>
//               <div className="relative">
//                 <input
//                   type={passwordVisible ? 'text' : 'password'}
//                   placeholder="At least 10 characters"
//                   name='password'
//                   value={loginValues.password}
//                   onChange={(e) => handleChange(e)}
//                   className="pr-10 "
//                   style={{ width: 'calc(105% - 40px)' }} 
//                 />
//                 <FontAwesomeIcon 
//                   icon={passwordVisible ? faEyeSlash : faEye} 
//                   onClick={togglePasswordVisibility}
//                   className="absolute right-10 top-1/2 transform -translate-y-1/2 cursor-pointer" 
//                 />
//               </div>
//               {errors.password && <ValidationTitle title={errors.password} />}
//             </div>

//             <div className="my-2">
//               <p className="float-end">
//                 Forgot <span className="text-[#1E4AE9] cursor-pointer" onClick={openForgotPasswordPopup}>Password?</span>
//               </p>
//             </div>
//             <button                
//                onClick={handleLogin}
//                 disabled={loginValues.is_form_submitted}
//                 className="flex justify m-0 "
//              >
//                 {loginValues.is_form_submitted ?   (<div className="flex justify-center items-center w-full h-full m-0"><Loader/></div>): 'Sign in'}
//              </button>            
//           </form>
//           <p className="already cursor-pointer" onClick={() => navigate('/register')} >
//             Don't you have an account? <span className="">Sign up</span> 
//           </p>

//           <div className="cop-right">
//             <ul>
//               <li>
//                 <div>© 2024 NetLife </div>
//               </li>
//               <li>
//                 <div>About Us</div>
//               </li>
//               <li>
//                 <div>Terms of Service</div>
//               </li>
//               <li>
//                 <div>Privacy Policy</div>
//               </li>
//             </ul>
//           </div>
//         </div>
//         <div className="login-image">
//           <div className="overlay">
//             <h3>
//               See your health care <br /> anytime, anywhere
//             </h3>
//             <img src={NetlifeRegLogo} alt="reg icon" />
//           </div>
//           <div></div>
//         </div>
//       </div>

//       {showForgotPasswordPopup && (
//         <ResetPassword onClose={closeForgotPasswordPopup} />
//       )}
//     </div>  
//   );
// }

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { logo, loginLogo } from "assests/dashBoard";
import useLoginForm from "hooks/useLoginForm";
import ValidationTitle from "components/shared/validation";
import RequestService from "services/requestService";
import { useDispatch } from 'react-redux';
import NetlifeRegLogo from "../../../../assests/icons/netlifRegLogo.svg";
import { setIsAuthenticated, setUserInfo } from '../../../../Features/auth/auth.slice';
import localStorageService from "services/localStorageService";
import Loader from "components/shared/Loader";
import ResetPassword from './ResetPassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function Login() {
  const {
    handleChange,
    validateLogin,
    errors,
    loginValues,
    setLoginErrors,
    setFormSubmitStatus
  } = useLoginForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = (e: any) => {
    e.preventDefault();
    const status = validateLogin();

    if (status) {
      submitFormData();
    }
  };

  const setErrorResponse = (errors: { [key: string]: string[] }) => {
    for (let key in errors) {
      setLoginErrors(key, errors[key][0]);
    }
  };

  const submitFormData = async () => {
    setFormSubmitStatus(true);

    RequestService.postMethod('login', loginValues)
      .then(async (response) => {
        const responseData = response.data.responseData;

        dispatch(setUserInfo(responseData));
        await localStorageService.setLocalStorageData('user', responseData);

        setFormSubmitStatus(false);
        dispatch(setIsAuthenticated(true));

        navigate('/dashboard');
      })
      .catch(error => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setLoginErrors('password', errorData?.responseData);
        }
        if (errorData?.status && errorData?.errors) {
          const errors = errorData.errors;
          setErrorResponse(errors);
        }
      });
  };

  const redirectToSignPage = (relativePath: string) => {
    navigate(relativePath);
  };

  const openForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <div className="registration-container">
        <div className="registration-form">
          <h2>We missed you!</h2>
          <p>
            Today is a new day. It's your day. Sign in to your account and get health care now.
          </p>
          <form >
            <div>
              <label>Email</label>
              <input
                type="text"
                placeholder="williamfavorx@gmail.com"
                value={loginValues.email}
                onChange={(e) => handleChange(e)}
                name="email"
              />
              {errors.email && <ValidationTitle title={errors.email} />}
            </div>

            <div>
              <label>Password</label>
              <div className="relative">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="At least 10 characters"
                  name='password'
                  value={loginValues.password}
                  onChange={(e) => handleChange(e)}
                  className="pr-10"
                  style={{ width: 'calc(100% - 0px)' }}
                />
                <FontAwesomeIcon
                  icon={passwordVisible ? faEyeSlash : faEye}
                  onClick={togglePasswordVisibility}
                  className="absolute right-10 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              </div>
              {errors.password && <ValidationTitle title={errors.password} />}
            </div>

            <div className="my-2">
              <p className="float-end">
                Forgot <span className="text-[#1E4AE9] cursor-pointer" onClick={openForgotPassword}>Password?</span>
              </p>
            </div>
            <button
              onClick={handleLogin}
              type="submit"
              disabled={loginValues.is_form_submitted}
              className="flex justify m-0"
            >
              {loginValues.is_form_submitted ? <div className="flex justify-center items-center w-full h-full m-0"><Loader /></div> : 'Sign in'}
            </button>
          </form>
          <p className="already cursor-pointer" onClick={() => navigate('/register')}>
            Don't you have an account? <span>Sign up</span>
          </p>

          <div className="cop-right">
            <ul>
              <li><div>© 2024 NetLife</div></li>
              <li><div>About Us</div></li>
              <li><div>Terms of Service</div></li>
              <li><div>Privacy Policy</div></li>
            </ul>
          </div>
        </div>
        <div className="login-image">
          <div className="overlay">
            <h3>
              See your health care <br /> anytime, anywhere
            </h3>
            <img src={NetlifeRegLogo} alt="reg icon" />
          </div>
        </div>
      </div>

      {showForgotPassword && (
        <ResetPassword onClose={closeForgotPassword} className="forgot-password-container" />
      )}
    </div>
  );
}
