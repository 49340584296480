import React, { ReactEventHandler, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import { StepProps } from "./stepsProps";
import "../../assests/styles/request-lab-service.css";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import {
  RadioInput,
  DateInput,
  Checkbox,
} from "components/InputComponents/InputComponents";

type HIVSymptom =
  | "none"
  | "nightSweats"
  | "persistentDiarrhea"
  | "oralThrush"
  | "other";
type HIVStatus = "negative" | "positive" | "unknown";
const CurrentHivInformation: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const PrepData = useSelector(
    (state: any) => state.hivCare.Pre_Exposure_Prophylaxis
  );
  const [file, setFile]= useState<File | null>()
  const [symptoms, setSymptoms] = useState<HIVSymptom[]>(PrepData.related_symptoms ?PrepData.related_symptoms:[]);
  const [status, setStatus] = useState<HIVStatus>(PrepData.hiv_status ?PrepData.hiv_status:"negative");
  const [testDate, setTestDate] = useState({
    month: "October",
    day: "03",
    year: "2009",
  });
const handleFileChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
if(e.target.files){
  setFile(e.target.files[0])
}
}
console.log(testDate)
console.log(file)

  const dispatch = useDispatch();
  const { GetPrepExposeProphylaxis } = useHivCare();
  var displayError = false;
  const setError = () => {
    displayError = false;
    if (symptoms.length === 0) {
      displayError = true;
      return "Please select atleast HIV-related symptoms";
    }
    if (!status) {
      displayError = true;
      return "Please select HIV Status";
    }
    if (testDate?.month ==='')  {
      displayError = true;
      return "Please select your test date";
    }

    displayError = false;
    return;
  };
  const errorMessage = setError();
  GetPrepExposeProphylaxis.PREPcurrentHivInformatio(dispatch, symptoms, status,testDate,file);

  const Checkoptions = [
    { value: "None", label: "None" },
    { value: "Night-Sweats", label: "Night Sweats" },
    { value: "Persistent-Diarrhea", label: "Persistent Diarrhea" },
    { value: "Other", label: "Other (please specify)" },
  ];

  const Radiooptions = [
    { id: 1, text: "HIV Negative" },
    {
      id: 2,
      text: "HIV Positive (Note: PEP is for HIV-negative individuals; if HIV positive, discuss treatment options with your healthcare provider)",
    },
    {
      id: 3,
      text: "Unknown/Undiagnosed (Note: HIV testing required before starting PEP)",
    },
  ];
  
console.log(testDate.month)
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something?
        </div>
      </div>
      <h3 className="heading3">Current HIV Information </h3>
      <div>
        <Checkbox
          options={Checkoptions}
          title="Recent HIV-related symptoms? (Select all that apply)"
          seData={setSymptoms}
          store_checked_values={symptoms}

        />
      </div>

      <div className="flex flex-col gap-2">
        <div>
          <RadioInput
            options={Radiooptions}
            title="Current HIV status?"
            setData={setStatus as any}
            selected_option={status}
            
          />
        </div>
      </div>

      {/* <div>
        <DateInput setDate={setTestDate as any} />
      </div> */}
      <div className="mt-2">
        <h3 className="heading3">Upload recent HIV Test Report</h3>

        <input
          className="block w-full text-sm text-gray-900 border border-white rounded-lg cursor-pointer bg-white dark:text-[#fff] focus:outline-none dark:bg-white dark:border-gray-600 dark:placeholder-stroke"
          id="file_input"
          type="file"
          onChange={handleFileChange}
        />
      </div>

      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
        Back
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={onNext}
          disabled={errorMessage? true : false}
        >
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default CurrentHivInformation;
