import { useEffect, useState } from "react";
import ArrowIcon from "../../assests/icons/circlright.svg";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import useRequestLabService from "hooks/useRequestLabService";
import { useSelector } from "react-redux";
import {
  setRedirectService
} from "Features/app/RequestLabService/HivCare.slice";
type labservice = {
  id: number
  text: string | null
}
type Labservices = {
  Labservices: labservice[] | null
  title: string | null
  subTitle: string | null
}
const TypeOfLabServices: React.FC<StepProps & Labservices> = ({ onNext, onPrevious, Labservices, title, subTitle }) => {
  const { dispatch, setSeletedLabTest } = useRequestLabService();
  const [selectedTest, setSelectedTest] = useState<string | null>(null);

  const handleTestClick = (test: string) => {
    dispatch(setSeletedLabTest(test));
    setSelectedTest(test)
  };


  const redirect_to_service = useSelector(
    (state: any) => state.hivCare.redirect_to_service
  );
  if (selectedTest !== null) {
    onNext();
  }

  useEffect(() => {
    if (redirect_to_service) {

      dispatch(setRedirectService(false))
      onNext();
    }

  }, [])
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something ?
        </div>
      </div>
      <div className="heading2">
        {title}
      </div>

      <p>
        {subTitle}
      </p>

      <div className="item-list-container">
        <ul className="item-list">
          {Labservices?.map((test, index) => (
            <li key={test.id}>
              <div onClick={() => handleTestClick(test.text as string)} className="item-link">
                {test.text}
                <span className="icon-container">
                  <img src={ArrowIcon} alt="Arrow Icon" className="icon" />
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div>


      </div>
    </div>


  );
};
export default TypeOfLabServices;
