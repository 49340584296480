import React, { useState,useEffect } from "react";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import { StepProps } from "components/RequestLabService/stepsProps";

import TimeInput from "./RequestLabService/TimeInput";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import { DateInput } from "./InputComponents/InputComponents";
import ConsentDatause from "./RequestLabServiceComponents/ConsentDatause";
import AdditionalInformation from "./RequestLabServiceComponents/AdditionalInformation";
import { useDispatch,useSelector } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
const MentalHealthServiceNeeds: React.FC<StepProps> = ({
  onNext,
  onPrevious,
}) => {
  const MentalHealth = useSelector(
    (state: any) => state.hivCare.Mental_Health_Services
  )
  const [serviceNeeds, setServiceNeeds] = useState<string[]>(MentalHealth.mental_health_services?MentalHealth.mental_health_services:[]);
  const [otherServiceNeeds, setOtherServiceNeeds] = useState<string>("");
  const [deliveryMode, setDeliveryMode] = useState<string>(MentalHealth.result_delivery_method?MentalHealth.result_delivery_method:"");
  const [date, setDate] = useState<string>("");
  const [preferedTime, setpreferedTime] = useState<string>("");
  const [additionalInfo, setAdditionalInfo] = useState<string>();
  const [facility_id, setFacilityId] = useState<number>(MentalHealth.facility_id);
  const [errors, setErrors] = useState({
    facility: false,
    deliveryMethod: false,
    deliveryDate: false,
    deliveryTime: false,
    serviceNeeds:false
   
  });
  const [enabled, setEnabled] = useState<boolean>(false);
  const handleServiceChange = (service: string) => {
    setServiceNeeds((prev) =>
      prev.includes(service)
        ? prev.filter((item) => item !== service)
        : [...prev, service]
    );
  };
  const dispatch = useDispatch();
  const { GetMentalHealth } = useHivCare();
  const validateForm = () => {
    const newErrors = {
      facility: !facility_id,
      deliveryMethod: !deliveryMode,
      deliveryDate: !date,
      deliveryTime: !preferedTime,
      serviceNeeds:serviceNeeds.length ===0
    };
    setErrors(newErrors);
    
    return Object.values(newErrors).every(error => !error);
  };
  const handleNext = () => {
    if (validateForm()) {
      GetMentalHealth.mentalhealthpreferences(
        dispatch,
        serviceNeeds,
        date,
        facility_id,
        preferedTime,
        additionalInfo,
        deliveryMode
      );
      onNext();
    }
  };
 
  var displayError = false;
  const setError = () => {
    displayError = false;
    if (
      deliveryMode === "" &&
      preferedTime === "" &&
      facility_id == null &&
      date === "" &&
      serviceNeeds.length ===0
    ) {
      displayError = true;
      return "Please select all the required fields";
    }

    displayError = false;
    return;
  };
  useEffect(() => {
 validateForm();
  }, [deliveryMode, date, preferedTime, additionalInfo, facility_id,serviceNeeds]);
  return (
    <div className="request-lab-container">
      <h2 className="mhs-h2">Mental Health Service Needs</h2>

      <div className="section space-y-6">
  <h3 className="text-xl font-semibold mb-4">
    What type of mental health services are you seeking? (Select all that apply)
  </h3>
  <div className="flex flex-col space-y-3">
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Individual Therapy"
        checked={serviceNeeds.includes("Individual Therapy")}
        onChange={() => handleServiceChange("Individual Therapy")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Individual Therapy</span>
    </label>
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Group Therapy"
        checked={serviceNeeds.includes("Group Therapy")}
        onChange={() => handleServiceChange("Group Therapy")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Group Therapy</span>
    </label>
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Psychiatric Evaluation"
        checked={serviceNeeds.includes("Psychiatric Evaluation")}
        onChange={() => handleServiceChange("Psychiatric Evaluation")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Psychiatric Evaluation</span>
    </label>
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Medication Management"
        checked={serviceNeeds.includes("Medication Management")}
        onChange={() => handleServiceChange("Medication Management")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Medication Management</span>
    </label>
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Crisis Intervention"
        checked={serviceNeeds.includes("Crisis Intervention")}
        onChange={() => handleServiceChange("Crisis Intervention")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Crisis Intervention</span>
    </label>
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Support Groups"
        checked={serviceNeeds.includes("Support Groups")}
        onChange={() => handleServiceChange("Support Groups")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Support Groups</span>
    </label>
    <label className="flex items-center space-x-3">
      <input
        type="checkbox"
        value="Other"
        checked={serviceNeeds.includes("Other")}
        onChange={() => handleServiceChange("Other")}
        className="form-checkbox h-5 w-5 text-indigo-600"
      />
      <span className="text-gray-700">Other</span>
    </label>

    {serviceNeeds.includes("Other") && (
      <input
        type="text"
        className="mt-2 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-white px-3 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        value={otherServiceNeeds}
        onChange={(e) => setOtherServiceNeeds(e.target.value)}
        placeholder="Please specify"
      />
    )}
  </div>
  {errors.serviceNeeds && <p className="error-message">Please select the mental health services you are seeking</p>}
  <div className="section space-y-4 mt-8">
    <h3 className="heading3">Preferred mode of service delivery:</h3>
    <div className="flex flex-col space-y-3">
      <label className="flex items-center space-x-3">
        <input
          type="radio"
          name="deliveryMode"
          value="In-person"
          checked={deliveryMode.includes("In-person")}
          onChange={() => setDeliveryMode("In-person")}
          className="form-radio h-5 w-5 text-indigo-600"
        />
        <span className="text-gray-700">In-person</span>
      </label>
      <label className="flex items-center space-x-3">
        <input
          type="radio"
          name="deliveryMode"
          value="Online"
          checked={deliveryMode.includes( "Online")}
          onChange={() => setDeliveryMode("Online")}
          className="form-radio h-5 w-5 text-indigo-600"
        />
        <span className="text-gray-700">Online</span>
      </label>
      <label className="flex items-center space-x-3">
        <input
          type="radio"
          name="deliveryMode"
          value="Telephone"
          checked={deliveryMode.includes("Telephone")}
          onChange={() => setDeliveryMode("Telephone")}
          className="form-radio h-5 w-5 text-indigo-600"
        />
        <span className="text-gray-700">Telephone</span>
      </label>
    </div>
  </div>
  {errors.deliveryMethod && <p className="error-message">Please select a delivery method.</p>}
</div>

      <h3 className="heading3">Select Facility</h3>
      <FacilitySelector  selectedfacilityId={setFacilityId} id={facility_id} />
      {errors.facility && <p className="error-message">Please select a facility.</p>}
      <div>
        <DeliveryDateTime  selectedDate={MentalHealth.preferred_date}
        selectedTime={MentalHealth.preferred_time} setDate={setDate} setTime={setpreferedTime} />
      </div>
      <h3 className="add_info">Additional Information:</h3>
      <div className="mb-6 mt-2">
        <AdditionalInformation setData={setAdditionalInfo} />
      </div>
      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
        Back
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={handleNext}
      
        >
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default MentalHealthServiceNeeds;