import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LabTestDoneTypes, RequestLabServiceState } from "./Types";
import {
  getFacilities,
  createRequestLabService,
} from "./RequestLabServiceActions";
const initialState: RequestLabServiceState = {
  LabTestDone: {
    SeletedOption: null,
    SeletedDateAndTime: null,
    SeletedTime: null,
    AddedAdditionalInformation: null,
    
  },
  location: {
    city: "Unknown City",
    country: "Unknown Country",
  },
  selectedLabTest: null,
  collectSampleFrom: null,
  getResultsFrom: null,
  getFacilities_loading: false,
  getFacilities_error: null,
  getFacilities_data: null,
  create_error_message: null,
  create_loading: false,
  create_error: null,
  create_message: null,
  patientServiceData: null,
  patientServiceFormStatus: false,
  
};

const RequestLabServiceSlice = createSlice({
  name: "RequestLabServiceSlice",
  initialState,
  reducers: {
    setLabTestDoneOption: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (state.LabTestDone) {
        state.LabTestDone.SeletedOption = payload;
      }
    },
    setLabTestDoneDateAndTime: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (state.LabTestDone) {
        state.LabTestDone.SeletedDateAndTime = payload;
      }
    },
    setLabTestDoneTime: (state, { payload }: PayloadAction<string | null>) => {
      if (state.LabTestDone) {
        state.LabTestDone.SeletedTime = payload;
      }
    },
    setLabTestDoneAdditionalInfo: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (state.LabTestDone) {
        state.LabTestDone.AddedAdditionalInformation = payload;
      }
    },
    setCollectSampleFrom1: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.collectSampleFrom = payload;
    },
    setGetResultsFrom: (state, { payload }: PayloadAction<string | null>) => {
      state.getResultsFrom = payload;
    },
    setSeletedLabTest: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedLabTest = payload;
    },
    setFormStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.patientServiceFormStatus = payload;
    },
    createRequestServiceErrorMessage: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.create_error_message = payload;
    },
    setPatientServiceData: (state, { payload }: PayloadAction<null>) => {
      state.patientServiceData = payload;
    },
    setCreateLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.create_loading = payload;
    },
    setCreateError: (state, { payload }: PayloadAction<string | null>) => {
      state.create_error = payload;
    },
  
  },
  extraReducers: (builder) => {
    builder.addCase(getFacilities.pending, (state) => {
      state.getFacilities_loading = true;
      state.getFacilities_error = null;
    });
    builder.addCase(getFacilities.fulfilled, (state, { payload }) => {
      state.getFacilities_loading = false;
      state.getFacilities_error = null;
      state.getFacilities_data = payload;
    });
    builder.addCase(createRequestLabService.pending, (state) => {
      state.getFacilities_loading = true;
      state.getFacilities_error = null;
    });
    builder.addCase(createRequestLabService.fulfilled, (state, { payload }) => {
      state.create_loading = false;
      state.create_error = null;
      state.create_message = payload as any;
      state.patientServiceFormStatus = true
   
    });
    builder.addCase(createRequestLabService.rejected, (state, { payload }) => {
      state.create_loading = false;
      state.create_error = null;
      
     
   
    });
  },
});

export const {
  setLabTestDoneOption,
  setLabTestDoneDateAndTime,
  setLabTestDoneTime,
  setLabTestDoneAdditionalInfo,
  setSeletedLabTest,
  setCollectSampleFrom1,
  setGetResultsFrom,
  createRequestServiceErrorMessage,
  setPatientServiceData,
  setFormStatus,
  setCreateLoading,
  setCreateError

} = RequestLabServiceSlice.actions;
export default RequestLabServiceSlice.reducer;
