import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../assests/styles/selectmediacenter.css";
import ArrowIcon from "../../assests/icons/angledown.svg";
import HospitalIcon from "../../assests/icons/Hospital.svg";
import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
import Loader from "components/shared/Loader";
import useStates from "hooks/useStates";
interface SelectDropdownProps {
  value: string | null;
  onChange: (value: string) => void;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({onChange, value}) => {
  const dispatch = useDispatch<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string | null>("");
  
const {getFacilities_data} = useStates()
console.log(getFacilities_data)
  const LabCenters = [
    {
      name: "MARP-Mulago",
      email: ["87tallen@gmail.com", "marp@net-life.com"],
      Phone: "+256 774 841923",
      WhatsApp: "+256 774 841923",
      Focal_Person: "Gorreti Katushabe",
    },
    {
      name: "Kiswa HCIII",
      email: ["98tallen@gmail.com", "Kiswa@net-life.com"],
      Phone: "+256 755 872853",
      WhatsApp: "+256 755 872853",
      Focal_Person: "Fiona Namono",
    },
    {
      name: "Kinsenyi HCIV",
      email: ["94tallen@gmail.com", "Kisenyi@net-life.com"],
      Phone: "+256 760 977973",
      WhatsApp: "+256 760 977973",
      Focal_Person: "Jackson Okello",
    },
  ];

  const options = getFacilities_data?.map((center:any) => center.name);

  
   
  

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  console.log(selectedOption)

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="select-dropdown">
      <div className="select-input  " onClick={toggleDropdown}>
        <span>{selectedOption || "Select an option"}</span>
        <img src={ArrowIcon} alt="Arrow Down" className="arrow-icon" />
        <img src={HospitalIcon} alt="Bag Icon" className="hospital-icon" />
      </div>
      {isOpen && (
        <ul className="dropdown-list overflow-y-auto h-40s border-grey  pl-4 rounded-sm">
          { options ?options.map((option:any) => (
            <li key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          )):( <div className="flex items-center text-[15px] text-primary_color ">
          Loading
        </div>)}
        </ul>
      )}
    </div>
  );
};

export default SelectDropdown;
