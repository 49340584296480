import React from 'react';

interface SelectOption {
    name: string;
    value: string;
}

interface InputFieldProps {
    value: string;
    handleChange: Function;
    title: string;
    placeholder: string;
    name: string;
    data: SelectOption[];
    className?: string; 
    style?: React.CSSProperties; 
}

const InputField: React.FC<InputFieldProps> = ({ value, data, handleChange, title, placeholder, name, className, style }) => {
    return (
        <div className={`mt-3 ${className}`} style={style}>
            <div>
                <label className="text-[15px] md:text-[18px] topographyText font-[400]">{title}</label>
            </div>
            <select
                // style={{ border: '1px solid #ccc',padding: '8px', borderRadius: '4px'}}
                name={name}
                className="border border-[#d4d7e3] mt-2 mb-4 w-full p-3 bg-r rounded-lg focus:outline-none focus:border-[#8a3ffc] focus:ring-0 placeholder:font-normal"

                value={value}
                onChange={(e) => handleChange(e)}
            >
                {/* <option value="">Select</option> */}
                {data.map(item => (
                    <option key={item.name} value={item.value}>{item.name}</option>
                ))}
            </select>
        </div>
    );
};

export default InputField;
