import React, { useState, useEffect, useCallback } from "react";
import NavBack from "../../assests/icons/arrowleftblack.svg";
import "../../assests/styles/registration.css";
import NetlifeRegLogo from "../../assests/icons/netlifRegLogo.svg";
import ValidationTitle from "components/shared/validation";
import { useNavigate } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FaCheck, FaTimes } from "react-icons/fa";

interface Register2Props {
  onBack: () => void;
  onNext: () => void;
  handleChange: (e: any) => void;
  registerValues: any;
  errors: any;
  validateRegister: Function;
}

const Register2: React.FC<Register2Props> = ({
  onBack,
  handleChange,
  registerValues,
  errors,
  validateRegister,
  onNext,
}) => {
  const [passwordValidation, setPasswordValidation] = useState<any>([]);
  const [showPasswordCheck, setShowPasswordcheck] = useState<boolean>(false);
  const [showPasswords, setShowPasswords] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    const isValidated = validateRegister(2);
    validatePassword(registerValues.password);
    if (isValidated) {
      onNext();
    }
  };

  const validatePassword = (password: string) => {
    const errors = [
      { message: "At least 8 characters long", isValid: true },
      { message: "Password should not exceed 16 characters", isValid: true },
      { message: "At least one lowercase letter", isValid: true },
      { message: "At least one uppercase letter", isValid: true },
      { message: "At least one number", isValid: true },
    ];

    if (password.length < 8) {
      const minLengthError = errors.find(
        (error) => error.message === "At least 8 characters long"
      );
      if (minLengthError) {
        minLengthError.isValid = false;
      }
    }

    if (password.length > 16) {
      const maxLengthError = errors.find(
        (error) => error.message === "Password should not exceed 16 characters"
      );
      if (maxLengthError) {
        maxLengthError.isValid = false;
      }
    }

    if (!/[a-z]/.test(password)) {
      const lowerCaseError = errors.find(
        (error) => error.message === "At least one lowercase letter"
      );
      if (lowerCaseError) {
        lowerCaseError.isValid = false;
      }
    }

    if (!/[A-Z]/.test(password)) {
      const upperCaseError = errors.find(
        (error) => error.message === "At least one uppercase letter"
      );
      if (upperCaseError) {
        upperCaseError.isValid = false;
      }
    }

    if (!/[0-9]/.test(password)) {
      const numberError = errors.find(
        (error) => error.message === "At least one number"
      );
      if (numberError) {
        numberError.isValid = false;
      }
    }

    setPasswordValidation(errors);

    // Check if all validation rules passed to enable the button
    const isValid = errors.every((error) => error.isValid);
    setIsFormValid(isValid);
  };

  const getColorClass = (isValid: boolean) => {
    return isValid ? "text-customGreen" : "text-customRed";
  };

  const displayPasswordCheck = useCallback(() => {
    let passwordInput = document.getElementById("passwordInput");

    if (document.activeElement === passwordInput) {
      setShowPasswordcheck(true);
    } else {
      setShowPasswordcheck(false);
    }
  }, []);

  useEffect(() => {
    displayPasswordCheck();
    validatePassword(registerValues.password);
  }, [registerValues.password]);

  return (
    <div className="registration-container">
      <div className="registration-form">
        <div className="nav-back cursor-pointer" onClick={onBack}>
          <img src={NavBack} alt="nav back" /> Back
        </div>
        <h2>Account security</h2>
        <form>
          <div className="mb-1">
            <label htmlFor="Email Address">Email Address</label>
            <input
              type="text"
              name="email"
              value={registerValues.email}
              onChange={handleChange}
              id="Email Address"
            />
            {errors.email && <ValidationTitle title={errors.email} />}
          </div>
          <div>
            <label htmlFor="passwordInput">Password</label>
            <input
              type={showPasswords ? "text" : "password"}
              name="password"
              value={registerValues.password}
              onChange={handleChange}
              id="passwordInput"
            />
            {showPasswordCheck && (
              <div className="text-left">
                <p className="font-semibold mb-2">
                  Your password must contain:
                </p>
                <ul className="list-none space-y-2">
                  {passwordValidation?.map((error: any, index: any) => (
                    <li
                      key={index}
                      className={`flex gap-2 justify-start items-center ${getColorClass(
                        error.isValid
                      )}`}
                    >
                      {error.isValid ? (
                        <span>
                          <FaCheck className="text-green-600" />
                        </span>
                      ) : (
                        <span>
                          <FaTimes className="text-red-600" />
                        </span>
                      )}{" "}
                      <p className="!m-0">{error.message}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div>
            <label>Confirm Password</label>
            <input
              type={showPasswords ? "text" : "password"}
              name="password_confirmation"
              value={registerValues.password_confirmation}
              onChange={handleChange}
            />
            {errors.password_confirmation && (
              <ValidationTitle title={errors.password_confirmation} />
            )}


            {errors.password && (
              <ValidationTitle title={errors.password} />
            )}


            {errors.custom_error && <ValidationTitle title={errors.custom_error} />}
          </div>

          <div className="show-password-container">
            <label>
              <input
                type="checkbox"
                checked={showPasswords}
                onChange={() => setShowPasswords(!showPasswords)}
              />
              Show Password
            </label>
          </div>

          <button
            type="button"
            disabled={!isFormValid || registerValues.is_form_submitted}
            onClick={handleSubmit}
            role="CreateAccount"
            className={!isFormValid ? "inactive" : ""}
          >
            {registerValues.is_form_submitted ? (
              <div className="flex justify-center items-center w-full h-full m-0">
                <Loader />
              </div>
            ) : (
              "Create Account"
            )}
          </button>
        </form>
        <p
          className="already cursor-pointer"
          onClick={() => navigate("/login")}
        >
          Already have an account? <span>Sign in</span>
        </p>
        <div className="cop-right">
          <ul>
            <li>
              <div>© 2024 NetLife </div>
            </li>
            <li>
              <div>About Us</div>
            </li>
            <li>
              <div>Terms of Service</div>
            </li>
            <li>
              <div>Privacy Policy</div>
            </li>
          </ul>
        </div>
      </div>

      <div className="registration-image">
        <div className="overlay">
          <h3>
            See your health care <br /> anytime, anywhere
          </h3>
          <img src={NetlifeRegLogo} alt="reg icon" />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Register2;
