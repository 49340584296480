import React, { useState, useEffect } from "react";
import NavBack from "../../assests/icons/arrowleftblack.svg";
import "../../assests/styles/registration.css";
import NetlifeRegLogo from "../../assests/icons/netlifRegLogo.svg";
import useForm from "hooks/useForm";
import ValidationTitle from "components/shared/validation";
import { useNavigate } from "react-router-dom";
import PhoneInputComponent from "components/shared/PhoneInputComponent";
import Loader from "components/shared/Loader";

interface Register1prop {
  onNext: () => void;
  handleChange: (e: any) => void;
  registerValues: any;
  errors: any;
  validateRegister: Function;
}

const Register1: React.FC<Register1prop> = ({
  onNext,
  handleChange,
  registerValues,
  errors,
  validateRegister,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false); // New state for form validation
  const navigate = useNavigate();

  // Effect to validate form when values change
  useEffect(() => {
    setIsFormValid(validateRegister());  // Validates the form on each change
  }, [registerValues, errors]); // Runs when registerValues or errors change

  const handleContinue = async () => {
    const isValidated = validateRegister();
    // setIsLoading(true);
    if (isValidated) {
      setIsLoading(true);
      onNext();
      setIsLoading(false);
    }
  };

  return (
    <div className="registration-container">
      <div className="registration-form">
        <div
          className="nav-back cursor-pointer"
          onClick={() => navigate("/login")}
        >
          <img src={NavBack} alt="nav back" /> Back
        </div>
        <h2>Sign Up for NetLife</h2>
        <p>
          Access healthcare anywhere. Connect, schedule, and manage your health.
          Create your account today!
        </p>
        <form>
          <div>
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              name="first_name"
              value={registerValues.first_name}
              onChange={handleChange}
              id="first_name"
            />
            {errors.first_name && <ValidationTitle title={errors.first_name} />}
          </div>

          <div>
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={registerValues.last_name}
              onChange={handleChange}
              id="last_name"
            />
            {errors.last_name && <ValidationTitle title={errors.last_name} />}
          </div>

          {/* <div>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              value={registerValues.username}
              onChange={handleChange}
              id="username"
            />
            {errors.username && <ValidationTitle title={errors.username} />}
          </div> */}

          <div>
            <label htmlFor="phone_number">Phone Number</label>
            <PhoneInputComponent
              name="phone_number"
              value={registerValues.phone_number}
              onChange={(value: string) =>
                handleChange({ target: { name: "phone_number", value } })
              }
              error={errors.phone_number} // Pass error message here

            />
            {errors.phone_number && (
              <ValidationTitle title={errors.phone_number} />
            )}
          </div>

          <button
            type="button"
            onClick={handleContinue}
            disabled={!isFormValid || isLoading}  // Disable button if form is invalid or loading
            className={!isFormValid ? "inactive" : ""}
          >
            {isLoading ? (
              <div className="flex justify-center items-center w-full h-full m-0">
                <Loader />
              </div>
            ) : (
              "Continue"
            )}
          </button>
        </form>
        <p
          className="already cursor-pointer"
          onClick={() => navigate("/login")}
        >
          Already have an account? <span className="">Sign in</span>
        </p>

        <div className="cop-right">
          <ul>
            <li>
              <div>© 2024 NetLife </div>
            </li>
            <li>
              <div>About Us</div>
            </li>
            <li>
              <div>Terms of Service</div>
            </li>
            <li>
              <div>Privacy Policy</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="registration-image">
        <div className="overlay">
          <h3>
            See your health care <br /> anytime, anywhere
          </h3>
          <img src={NetlifeRegLogo} alt="reg icon" />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Register1;
