import React from 'react';



interface FieldLabelText {


    title: string,

}

const FieldLabelText: React.FC<FieldLabelText> = ({ title }) => {


    return (

        <div className='mt-3'>
            <label>{title}</label>
        </div>

    );


}

export default FieldLabelText;