import React, { useState } from "react";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import { useDispatch,useSelector } from "react-redux";
import {
  RadioInput,
  Checkbox,
} from "components/InputComponents/InputComponents";
import { StepProps } from "./stepsProps";
import AdditionalInformation from "./AdditionalInformation";
import "../../assests/styles/request-lab-service.css";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
const CurrentMentalHealth: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const mentalHealthData = useSelector(
    (state: any) => state.hivCare.Mental_Health_Services
  );
  const [currentHealthConcerns, setCurrentMentalHealth] = useState<string[]>(
    mentalHealthData.mental_health_issues?mentalHealthData.mental_health_issues:[]
  );
  const [rencentSystoms, setAdditionalInformation] = useState<string[] | null>(
    mentalHealthData.mental_experienced_symptoms?mentalHealthData.mental_experienced_symptoms:[]
  );
  const dispatch = useDispatch();
  const { GetMentalHealth } = useHivCare();
  const Questions = [
    {
      question: "Current Mental Health Concerns? (Select all that apply)",
      options: [
        { value: "none", label: "None" },
        { value: "anxiety", label: "Anxiety" },
        { value: "depression", label: "Depression" },
        { value: "stress", label: "Stress" },
        { value: "mood_swings", label: "Mood Swings" },
        { value: "insomnia", label: "Insomnia" },
        { value: "panic_attacks", label: "Panic Attacks" },
        { value: "other", label: "Other" },
      ],
    },
    {
      question:
        "Have you experienced any of the following symptoms recently? (Select all that apply)",
      options: [
        { value: "none", label: "None" },
        {
          value: "loss_of_interest",
          label: "Loss of Interest or Pleasure in Activities",
        },
        {
          value: "changes_in_appetite",
          label: "Changes in Appetite or Weight",
        },
        { value: "fatigue", label: "Fatigue or Lack of Energy" },
        { value: "worthlessness", label: "Feelings of Worthlessness or Guilt" },
        {
          value: "difficulty_concentrating",
          label: "Difficulty Concentrating",
        },
        {
          value: "self_harm_thoughts",
          label: "Thoughts of Self-Harm or Suicide",
        },
        { value: "other", label: "Other" },
      ],
    },
  ];

  GetMentalHealth.mentalHealthStatus(
    dispatch,
    currentHealthConcerns,
    rencentSystoms
  );

  var displayError = false;
  const setError = () => {
    displayError = false;
    if (currentHealthConcerns.length === 0) {
      displayError = true;
      return "Please select your current Health concerns";
    } else if (rencentSystoms === null) {
      displayError = true;
      return "Please select atleast one recent systoms";
    }

    displayError = false;
    return;
  };
  const errorMessage = setError()
  return (
    <div className="request-lab-container">
      <div>
        <Checkbox
          options={
            (Questions.find(
              (question) =>
                question.question ===
                "Current Mental Health Concerns? (Select all that apply)"
            )?.options as any) || null
          }
          title="Current Mental Health Concerns? (Select all that apply)"
          seData={setCurrentMentalHealth}
          store_checked_values={currentHealthConcerns}
        />
      </div>
      <div>
        <Checkbox
          options={
            (Questions.find(
              (question) =>
                question.question ===
                "Have you experienced any of the following symptoms recently? (Select all that apply)"
            )?.options as any) || null
          }
          title="Have you experienced any of the following symptoms recently? (Select all that apply)"
          seData={setAdditionalInformation}
          store_checked_values={rencentSystoms as string[]}
        />
      </div>

      <div className="flex gap-2">
        <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button type="submit" className="submit-button" onClick={onNext} disabled={errorMessage ? true : false}>
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>

    </div>
  );
};
export default CurrentMentalHealth;
