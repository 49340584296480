import React, { useState, useRef, useEffect } from 'react';
// import Footer from "layout/patient/footer";
import Header from "layout/patient/header";
import MobileSideNavBar from "layout/patient/MobileSideNavBar";
import LargeScreenNavBar from 'layout/patient/LargeScreenNavBar';
import MobileHeader from 'layout/patient/MobileHeader';

const SavedServiceMobile: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleRemoveClick = () => {
    console.log('Navigating to remove functionality');
  };

  const handleGetDirectionClick = () => {
    console.log('Navigating to specific directory');
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div>
      <Header />
      <LargeScreenNavBar />
      {/* Pass required props to MobileHeader */}
      
     <div style={{marginRight: '10%',
    marginLeft: '10%', marginTop:'-15%'}}>
      <div className="block md:hidden">
        <div className="md:w-90%" >
          <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
          <div className="w-90% h-auto gap-0 border-2 border-[#8A3FFC] rounded-lg bg-blue-100 p-4">
            <div className="flex justify-between items-center p-4">
              <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
              <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                Remove
              </div>
            </div>
            <div className="flex justify-between items-center p-4">
              <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
              <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#8A3FFC' }} onClick={handleGetDirectionClick}>
                Get Direction
              </div>
            </div>
            <div className="p-4">
              <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
            </div>
            <div className="p-4">
              <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>

      
      <div className="md:w-90%" >
          <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
          <div className="w-90% h-auto gap-0 border-2 border-[#8A3FFC] rounded-lg bg-blue-100 p-4">
            <div className="flex justify-between items-center p-4">
              <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
              <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                Remove
              </div>
            </div>
            <div className="flex justify-between items-center p-4">
              <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
              <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#8A3FFC' }} onClick={handleGetDirectionClick}>
                Get Direction
              </div>
            </div>
            <div className="p-4">
              <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
            </div>
            <div className="p-4">
              <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}


      
      {/* <div className="block md:block" style={{ width: "45%", marginLeft: "39%" }}> */}
        <div className="md:w-90%" >
          <h1 className="text-xl font-bold mb-4">Saved Service Centre</h1>
          <div className="w-90% h-auto gap-0 border-2 border-[#8A3FFC] rounded-lg bg-blue-100 p-4">
            <div className="flex justify-between items-center p-4">
              <div className="font-poppins text-25 font-semibold" style={{ color: '#00929D' }}>ASDA Pharmacy</div>
              <div className="cursor-pointer font-poppins text-18 font-medium text-black" onClick={handleRemoveClick}>
                Remove
              </div>
            </div>
            <div className="flex justify-between items-center p-4">
              <div className="font-poppins text-18 font-semibold text-black">Kampala, William street, block A01</div>
              <div className="cursor-pointer font-poppins text-18 font-medium" style={{ color: '#8A3FFC' }} onClick={handleGetDirectionClick}>
                Get Direction
              </div>
            </div>
            <div className="p-4">
              <div className="font-poppins text-18 font-normal leading-27 text-black">Mon - Fri : 9:00 am - 11:00 pm</div>
            </div>
            <div className="p-4">
              <div className="font-poppins text-18 font-normal leading-27 text-black">+246 394 202 752</div>
            </div>
          </div>
        </div>
        </div>
        {/* <Footer /> */}
      </div>

   
  );
};

export default SavedServiceMobile;
