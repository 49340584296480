import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { HivTestQuestions } from "./HivScreenQuestions";
import { Checkbox, CheckboxProps } from "@material-tailwind/react";
import checkboxColors from "@material-tailwind/react/theme/components/checkbox/checkboxColors";
type Checkbox = {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function HivScreeningPage() {
  return (
    <div className="w-full ">
      <div className="md:p-4 flex flex-col gap-10 md:mx-30">
        <div className="flex md:gap-4 items-center justify-start gap-2">
          <span className="text-[#8A3FFC] font-[500]" >
            <FaArrowLeftLong />
          </span>
          <h3 className="topographyText text-[#8A3FFC] font-[500] text-[20px]">Forgot something ?</h3>
        </div>
        <div>
          <h3 className="topographyText text-[30px] md:text-[40px] font-[600] text-[#000] w-full mb-6 md:mb-0">HIV Screening Questions</h3>
          <p className="topographyText text-[18px] md:text-[25px] font-fontsm  w-full md:w-[600px]">
            Please answer these questions for yourself or your sexual partner
          </p>
        </div>
        <div>
          {HivTestQuestions.map((question, index) => {
            return (
              <div key={index}>
                <p className="topographyText text-[15px] md:text-[15px] font-[400]">
                  {question.No}. {question.title}
                </p>
                <div className="flex gap-8 mt-2">
                  <div className="flex items-center mb-4">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 topographyText"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center mb-4 ">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="default-checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 topographyText"
                    >
                      No
                    </label>
                  </div>
                 
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center" >
            <button className="topographyText bg-primary_color px-15 py-2 md:px-30 md:py-4 text-[#fff] font-[600]">
            Analyze Risk
            </button>
        </div>
      </div>
    </div>
  );
}
