import React, { useState, useEffect, useRef } from "react";
import { useDispatch ,useSelector } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import useStates from "hooks/useStates";
import ConsentDatause from "./RequestLabServiceComponents/ConsentDatause";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import { DateInput } from "./InputComponents/InputComponents";
import useRequestLabService from "hooks/useRequestLabService";


const PEPRequestDetails: React.FC<StepProps> = ({ onNext, onPrevious }) => {

  const PrepRequest = useSelector(
    (state: any) => state.hivCare.Pre_Exposure_Prophylaxis

  ); 
   const { lapServiseState } = useRequestLabService();

  const { LabTestDone, selectedLabTest, collectSampleFrom, getResultsFrom } =
  lapServiseState;
  const { getFacilities_data } = useStates();
let facilityName = null;
for (let i = 0; i < getFacilities_data?.length; i++) {
  if (getFacilities_data[i].name === LabTestDone?.SeletedOption) {
    facilityName = getFacilities_data[i].name;
    break;
  }
}
  const inputRef = useRef<any>();
  const [selectedFile, setSelectedFile] = useState<File | null>(PrepRequest.report_file_uri?PrepRequest.report_file_uri:null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("select");
  const [preferredMedication, setPreferredMedication] = useState<string>("");
  const [otherMedication, setOtherMedication] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState<string>( PrepRequest.result_delivery_method ?PrepRequest.result_delivery_method :"");
  const [facilityStatus, setFacilityStatus] = useState<string>("");
  const [otherFacilityStatus, setOtherFacilityStatus] = useState<string>("");
  const [facility_id, setFacilityId] = useState<number>(PrepRequest.facility_id);
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>(PrepRequest.facility_id?PrepRequest.facility_id :"");
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<string>( PrepRequest.preferred_date?PrepRequest.preferred_date :"");
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<string>(PrepRequest.preferred_time ?PrepRequest.preferred_time: "");
  const [testDate, setTestDate] = useState(PrepRequest.last_test_date ?PrepRequest.last_test_date:
    {
    month: "October",
    day: "03",
    year: "2009",
  });
  const [errors, setErrors] = useState({
    facility: false,
    deliveryMethod: false,
    deliveryDate: false,
    deliveryTime: false,
    testDate: false,
    selectedFiles: false
  });
  const [enabled, setEnabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { GetPrepExposeProphylaxis } = useHivCare();
 
  
  const postRequestService = useDispatch<any>();
  
  // Ensure getFacilities_data is always an array
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const onChooseFile = () => {
    inputRef.current.click();
  };
  console.log("file" + selectedFile?.name);
  const handleMedicationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPreferredMedication(e.target.value);
    if (e.target.value !== "Other") {
      setOtherMedication("");
    }
  };

  const handleFacilityStatusChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFacilityStatus(e.target.value);
    if (e.target.value !== "Other") {
      setOtherFacilityStatus("");
    }
  };
  

  // Ensure getFacilities_data is always an array
  const facilities = Array.isArray(getFacilities_data) ? getFacilities_data : [];
  let selectedFacilityName = null;
  for (let i = 0; i < facilities.length; i++) {
    if (facilities[i].id === selectedFacilityId) {
      selectedFacilityName = facilities[i].name;
      break;
    }
  }
  const validateForm = () => {
    const newErrors = {
      facility: !selectedFacilityId,
      deliveryMethod: !deliveryMethod,
      deliveryDate: !selectedDeliveryDate,
      deliveryTime: !selectedDeliveryTime,
      testDate: !testDate,
      selectedFiles: selectedFile === null
    };
    setErrors(newErrors);
    
    return Object.values(newErrors).every(error => !error);
  };
  useEffect(()=>{
    validateForm();
  },[selectedFacilityId,deliveryMethod,DeliveryDateTime,testDate,selectedFile])

  GetPrepExposeProphylaxis.PrePRequestDetails(
    dispatch,
    preferredMedication,
    deliveryMethod,
    selectedFacilityId,
    selectedDeliveryDate,
    selectedDeliveryTime,
    testDate,
    selectedFile
  );
  var displayError = false;
  const setError = () => {
    displayError = false;
    if (
      preferredMedication === "" &&
      selectedFacilityId ==="" &&
      deliveryMethod === "" &&
      selectedFacilityId === null &&
      selectedDeliveryDate === "" &&
      selectedDeliveryTime === ""
    ) {
      displayError = true;
      return "Please select all the required fields";
    }else{
      displayError = false;
    }

    
    return;
  };
  console.log({
    selectedFacilityId,
    selectedDeliveryDate,
    selectedDeliveryTime,
    deliveryMethod,
    preferredMedication,
    testDate,
    selectedFile,
    displayError
  })
  const handleNext = () => {
    if (validateForm()) {
      GetPrepExposeProphylaxis.PrePRequestDetails(
        dispatch,
        preferredMedication,
        deliveryMethod,
        selectedFacilityId,
        selectedDeliveryDate,
        selectedDeliveryTime,
        testDate,
        selectedFile
      );
      onNext();
    }
  };
  return (
    <div className="request-lab-container">
      <h3 className="heading2">Your PreP Request Details</h3>
      <FacilitySelector  selectedfacilityId={setSelectedFacilityId}  id={selectedFacilityId}/>{" "}
      {errors.facility && <p className="error-message">Please select a facility.</p>}
      {/* Added FacilitySelector component */}
      <div className="section">
        <div>
          <DateInput  selectedTestDate={PrepRequest.last_test_date} setDate={setTestDate as any} />
          {errors.testDate && <p className="error-message">Please select your last Hiv test date.</p>}
        </div>
        <div className="mt-2">
          <h3 className="heading3">Upload recent HIV Test Report</h3>

          <div className="mb-5">
            <input
              className="block w-full text-sm  border border-white rounded-lg cursor-pointer bg-white  focus:outline-none  "
              id="file_input"
              type="file"
              
              onChange={handleFileChange}
            />
             {errors.selectedFiles && <p className="error-message">Please select your last Hiv report.</p>}
          </div>
        </div>
        {/* <h3 className="heading-3">Preferred PEP Medication:</h3>
        <div className="radio-group">
          <label>
            <input 
              type="radio"
              name="preferredMedication"
              value="Truvada"
              checked={preferredMedication === "Truvada"}
              onChange={handleMedicationChange}
            />
            Truvada (tenofovir/emtricitabine)
          </label>
          <label>
            <input
              type="radio"
              name="preferredMedication"
              value="Descovy"
              checked={preferredMedication === "Descovy"}
              onChange={handleMedicationChange}
            />
            Descovy (tenofovir alafenamide/emtricitabine)
          </label>
          <label>
            <input
              type="radio"
              name="preferredMedication"
              value="Other"
              checked={preferredMedication === "Other"}
              onChange={handleMedicationChange}
            />
            Other
          </label>
          {preferredMedication === "Other" && (
            <input
              type="text"
              className="mt-2 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-white dark:bg-white dark:border-gray-600 dark:placeholder-stroke"
              value={otherMedication}
              onChange={(e) => setOtherMedication(e.target.value)}
              placeholder="Please specify"
            />
          )}
        </div> */}
      </div>
      <h3 className="heading3">Preferred Delivery Method</h3>
      <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] space-y-4">
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Home"
            checked={deliveryMethod.includes("Home")}
            onChange={() => setDeliveryMethod("Home")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Home</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Workplace"
            checked={deliveryMethod.includes( "Workplace")}
            onChange={() => setDeliveryMethod("Workplace")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Workplace</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Health Facility"
            checked={deliveryMethod.includes( "Health Facility")}
            onChange={() => setDeliveryMethod("Health Facility")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Health Facility</span>
        </label>
      
      </div>
      {errors.deliveryMethod && <p className="error-message">Please select a delivery method.</p>}
      <DeliveryDateTime
       
        setTime={setSelectedDeliveryTime}
        setDate={setSelectedDeliveryDate}
        selectedDate={PrepRequest.preferred_date}
        selectedTime={PrepRequest.preferred_time}
      />{" "}
      {/* Added DeliveryDateTime component */}
      <div className="section">
        {/* <h3 className="heading-3">Facility Relationship Status</h3>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="facilityStatus"
              value="New"
              checked={facilityStatus === "New"}
              onChange={handleFacilityStatusChange}
            />
            New
          </label>
          <label>
            <input
              type="radio"
              name="facilityStatus"
              value="Existing"
              checked={facilityStatus === "Existing"}
              onChange={handleFacilityStatusChange}
            />
            Existing
          </label>
          <label>
            <input
              type="radio"
              name="facilityStatus"
              value="Other"
              checked={facilityStatus === "Other"}
              onChange={handleFacilityStatusChange}
            />
            Other
          </label>
          {facilityStatus === "Other" && (
            <input
              type="text"
              className="mt-2 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-white dark:bg-white dark:border-gray-600 dark:placeholder-stroke"
              value={otherFacilityStatus}
              onChange={(e) => setOtherFacilityStatus(e.target.value)}
              placeholder="Please specify"
            />
          )}
        </div> */}
      </div>
      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button type="submit" className="submit-button" onClick={handleNext}>
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default PEPRequestDetails;