import { useState } from "react";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import CloseIcon from "../../assests/icons/close-icon.svg";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import useStates from "hooks/useStates";

type LabServiceEligiblePatientProps = {
  introduction: string | null;
  tip: string | null;
  CTA:string | null;
  StepTowards:string | null;
};
const LabServiceEligiblePatient: React.FC<
  StepProps & LabServiceEligiblePatientProps
> = ({ onNext, onPrevious, introduction, tip,CTA ,StepTowards}) => {
  const { first_name } = useStates();
  const [isTipVisible, setIsTipVisible] = useState(true); //this state is for managing the tip visibility

  const handleCloseTip = () => {
    setIsTipVisible(false); // Hide tip container when close icon is clicked
  };

  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something ?
        </div>
      </div>
      <div className="heading2">
        {StepTowards}, {first_name || null}!
      </div>

      <p>{introduction}</p>

      {isTipVisible && (
        <div className="tip-container">
          <button className="close-tip" onClick={handleCloseTip}>
            <img className="img-close-tip" src={CloseIcon} alt="close" />
          </button>
          <h6 className="sel-note-heading">Tip</h6>

          <p className="sel-note">{tip}</p>
        </div>
      )}

      <div className="sel-test">
        <button onClick={onNext}>{CTA}</button>
        {/* <button onClick={onNext}>Self Testing</button> */}
      </div>
    </div>
  );
};
export default LabServiceEligiblePatient;
