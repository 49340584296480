import FirstStage from "components/findService/firstStage";
import SecondStage from "components/findService/secondStage";
import Button from "components/shared/button";
import { useState } from "react";
import '../../../../assests/styles/find_service.css'



const FindService = () => {
    const [formData, setValue] = useState({

    });
    const [formStage, setFormStage] = useState(1);

    const setInputField = (formDataRecord: Object) => {
        setValue({ ...formData, ...formDataRecord })
    }

    const onClick = () => {
        if (formStage === 1) {
            setFormStage(2);
        }
        if (formStage === 2) {

            setFormStage(1);
        }


    }




    return (<div className="find_service_container">

        {formStage == 1 && <FirstStage setFormData={(formData: Object) => setInputField(formData)} />}

        {formStage == 2 &&<SecondStage goBack={()=>onClick()} setFormData={(formData: Object) => setInputField(formData)} />}
        <Button title="Search" onClick={() => onClick()} />


    </div>)
}

export default FindService;