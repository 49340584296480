import React, { useState } from "react";
import Register1 from "../../../../../components/Registration/Register1";
import Register2 from "../../../../../components/Registration/Register2";
import useForm from "hooks/useForm";
import EmailCodeVarification from "components/Registration/EmailCodeVarification";
import RequestService from "services/requestService";
import localStorageService from "services/localStorageService";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../../../../Store';
import { useNavigate } from "react-router-dom";

import { setIsAuthenticated, setUserInfo } from '../../../../../Features/auth/auth.slice';
import ToastMessage from "components/shared/ToastMesaage";

const PatientRegistration: React.FC = () => {
  const { handleChange, registerValues,
    setFormSubmitStatus,
    errors, setRegisterErrors, validateRegister } = useForm(
      true,
      "register"
    );
  const [step, setStep] = useState(1);
  const [is_code_reset, setResend] = useState(false);

  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const [toastMessage, setToastMessage] = useState({
    status: '',
    message: ''
  });


  const dispatch = useDispatch();

  const navigate = useNavigate();


  const moveToNextStage = (stage: number) => {

    if (stage === 3) {
      saveUserData();

      return;
    }

    setStep(stage);

  }

  const saveUserData = () => {


    setFormSubmitStatus(true);

    const formBody = {
      ...registerValues, ...{ stage: 1 }
    }

    RequestService.postMethod('register', formBody)

      .then(async (response) => {
        const responseData = response.data.responseData;

        const responseObject = { ...responseData, ...{ stage: 2 } };

        dispatch(setUserInfo(responseData));

        await localStorageService.setLocalStorageData('user', responseObject);
        setFormSubmitStatus(false);

        setStep(3);


      })
      .catch(error => {



        setFormSubmitStatus(false);



        const errorData = error?.response?.data;

        if (errorData?.responseData) {
          setRegisterErrors('password', errorData?.responseData)
        }
        if (errorData?.errors) {
          const errors = errorData?.errors;
          // alert("yes")
          setErrorResponse(errors);
        }

      })
  }


  const setErrorResponse = (errors: any) => {

    for (let key in errors) {
      setRegisterErrors(key, errors[key][0]);
      if (key === 'phone_number') {
        setRegisterErrors('custom_error', errors[key][0]);
        setToastMessage({
          ...toastMessage, ...{
            status: 'error',
            message: errors[key][0]
          }
        })
      }

      if (key === 'username') {
        setRegisterErrors('custom_error', errors[key][0]);
        setToastMessage({
          ...toastMessage, ...{
            status: 'error',
            message: errors[key][0]
          }
        })
      }





    }
  }

  const verifyEmail = async (code: string) => {


    if (code.length < 5) {

      setErrorResponse({
        invalid_code: ['Please input 6 digit number']
      });
      return;
    }

    setErrorResponse({
      invalid_code: ['']
    });

    setFormSubmitStatus(true);

    const body = {
      email: userInfo?.email,
      verification_code: Number(code)
    }

    RequestService.postMethod('verify-code', body)
      .then((response) => {
        const responseData = response.data.responseData;
        if (responseData) {
          // setStep(4);
          setFormSubmitStatus(false);
          dispatch(setIsAuthenticated(true));
          dispatch(setUserInfo(responseData));

          //navigate('/verifyPhone');
          navigate('/userform');
        }

      })
      .catch(error => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;


        if (errorData?.responseData) {
          setRegisterErrors('invalid_code', errorData?.responseData)
        }
        if (errorData?.status && errorData?.errors) {
          const errors = errorData.errors;
          setErrorResponse(errors);
        }

      })


  }

  const resendCode = () => {



    setErrorResponse({
      invalid_code: ['']
    });

    setResend(true);

    const body = {
      email: userInfo?.email
    }

    RequestService.postMethod('resend-code', body)
      .then((response) => {
        const responseData = response.data.responseData;
        if (responseData) {
          // setStep(4);

        }
        setResend(false);

      })
      .catch(error => {
        setResend(false);
        const errorData = error?.response?.data;


        if (errorData?.responseData) {
          setRegisterErrors('invalid_code', errorData?.responseData)
        }
        if (errorData?.status && errorData?.errors) {
          const errors = errorData.errors;
          setErrorResponse(errors);
        }

      })
  }


  return (
    <div className="w-full">

      {step === 1 && (
        <Register1
          onNext={() => moveToNextStage(2)}
          handleChange={handleChange}
          registerValues={registerValues}
          errors={errors}
          validateRegister={() => validateRegister(step)}
        />
      )}
      {step === 2 && (
        <Register2
          onBack={() => moveToNextStage(1)}
          onNext={() => moveToNextStage(3)}
          handleChange={handleChange}
          registerValues={registerValues}
          errors={errors}
          validateRegister={() => validateRegister(step)}
        />
      )}
      {step === 3 && (
        <div>
          <EmailCodeVarification onNext={(code: string) => verifyEmail(code)}
            errors={errors}
            resendCode={resendCode}
            registerValues={registerValues}
            is_code_reset={is_code_reset}
          />
        </div>
      )}


      <ToastMessage message={toastMessage.message} status={toastMessage.status} />

    </div>
  );
};

export default PatientRegistration;
