import React from "react";
import { IoCloseSharp } from "react-icons/io5";
export default function RequestRefill({handleClick}:any) {
  return (
    <div className="w-[700px] h-[117px] bg-[#ffff] bg-opacity-100 z-1">
      <div>
        <div className="bg-[#8A3FFC] ">
          <div className="mx-[12%] flex justify-between items-center p-4">
          <h2 className="topographyText text-[#fff] text-[25px] font-[600]">Refill Request</h2>
          <span className="text-[#fff] border-x-grey" >
            <IoCloseSharp className="w-[40px] h-[40px] border-grey" />
          </span>
          </div>
        </div>
        <div className="p-10 flex flex-col justify-center mx-[10%]">
          <p className="topographyText  flex gap-8 text-[15px] font-[500]">
          Medication Name:
            <span className="font-[400]">Aspirin 500 mg  oral tablet</span>
          </p>
          <p className="topographyText  flex gap-28 text-[18px]  font-[500] ">
           Dosage<span className="font-[400]">1 tablet</span>
          </p>
          <p className="topographyText  flex gap-16 text-[18px] font-[500]">
          Frequency:<span>Once a day</span>
          </p>
          <p className="topographyText  flex gap-8 text-[18px] font-[500]">
          Administration<span className="font-[400]">After a meal</span>
          </p>
          <p className="topographyText  flex gap-8 text-[18px] font-[500]">
          Physician Name<span className="font-[400]">Dr.James Tusiime</span>
          </p>
          <p className="topographyText  flex gap-8 text-[18px] font-[500]">
          Preferred Pharmacy <span className="font-[400]">yyy Pharmacy</span> <button className="text-[18px] text-[#8A3FFC]">change</button>
          </p>
        
        </div>
        <div className="pl-8 flex flex-col gap-4 mx-[10%]">
            <h3 className="text-[18px] font-[500] topographyText ">Any changes in medical condition since last refill </h3>
            <input
              className="border-2 border-[#BFBFBF] bg-white h-30 px-5 pr-8 text-sm focus:outline-none w-[500px]"
              type="search"
              name="search"
            />
        </div>
        <div className="flex justify-end">
          <button className="border-[#8A3FFC] p-2 text-[#8A3FFC] topographyText font-[600] rounded-[6px] border-2" >
            Request Refill
          </button>
  </div>
      </div>
    </div>
  );
}
