import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import SelectDropdown from "./selectmedcenter";
import DateInput from "./DateInput";
import TimeInput from "./TimeInput";
import TimeInput2 from "./TimeInput2";
import TextInput from "./TextInput";
import { StepProps } from "./stepsProps";
import "../../assests/styles/request-lab-service.css";
import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
import { setLabTestDoneDateAndTime, setLabTestDoneTime, setLabTestDoneOption, } from "Features/app/RequestLabService/requestLabServices.slice";
type RequestLabServiceActions ={
  title:string | null
}
const RequestLabservice7: React.FC<StepProps & RequestLabServiceActions> = ({ onNext, onPrevious,title }) => {
  const dispatch = useDispatch<any>();
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    if (!selectedFacility) newErrors.selectedFacility = "Please select a facility.";
    if (!selectedDate) newErrors.selectedDate = "Please select a date.";
    if (!selectedTime) newErrors.selectedTime = "Please select a time.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
useEffect(()=>{
  dispatch(getFacilities())
},[])
  const handleSubmit = () => {
    if (validateFields()) {
      dispatch(setLabTestDoneOption(selectedFacility));
      dispatch(setLabTestDoneDateAndTime(selectedDate));
      dispatch(setLabTestDoneTime(selectedTime));
      console.log("Form submitted!");
      onNext();
    }
  };

  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something?
        </div>
      </div>

      <div className="heading2">{title ||null}</div>
      <p>Choose the lab facility that best suits your needs.</p>
      <SelectDropdown value={selectedFacility} onChange={setSelectedFacility} />
      {errors.selectedFacility && <p className="error">{errors.selectedFacility}</p>}

      <div className="heading4">Date and Time</div>
      <p>What is your preferred date and time for us to collect the sample from you?</p>
      <DateInput value={selectedDate} onChange={setSelectedDate} />
      {errors.selectedDate && <p className="error">{errors.selectedDate}</p>}

      <p>Select time</p>
      <TimeInput value={selectedTime} onChange={setSelectedTime} />
      {errors.selectedTime && <p className="error">{errors.selectedTime}</p>}

      <div className="heading4">Additional Information</div>
      <p>Any additional information or special requests?</p>
      <TextInput value={additionalInfo} onChange={setAdditionalInfo} />

      <button className="submit-button" onClick={handleSubmit}>
        Confirm Order
      </button>
    </div>
  );
};

export default RequestLabservice7;
