import React, { useEffect, useState } from 'react'
import '../../../src/assests/styles/PepCurrentHivInformation.css';
import { selected } from '@material-tailwind/react/types/components/select';
type CheckboxOptions = {
  value: string,
  label: string,

}



type CheckboxProps = {
  options: CheckboxOptions[]
  title: string
  seData: (selected: any) => void
  store_checked_values:string[]
}
export const Checkbox: React.FC<CheckboxProps> = ({ options, title, seData, store_checked_values }) => {

  const [hivSymptoms, setHivSymptoms] = useState<string[]>(store_checked_values?store_checked_values:[]);
  const [otherHivSymptom, setOtherHivSymptom] = useState<string>('');

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {

    const { name, checked } = e.target;

    const isNon = name && name.toLowerCase() === 'none';

    const containsNone = hivSymptoms.includes('None');



    if (containsNone && !isNon) {
      return;
    }

    if (containsNone && isNon && !checked) {


      setHivSymptoms([...[]]);

      return;
    }

    const propertyExists = hivSymptoms.includes(name);

    if (propertyExists && !checked) {

      const copy = [...hivSymptoms];

      const index = copy.indexOf(name);
      copy.splice(index, 1);

      setHivSymptoms([...copy]);
      return;

    }


    if (hivSymptoms.length > 0 && isNon) {

      return;
    }

    setHivSymptoms(prev =>
      checked ? [...prev, name] : prev.filter(item => item !== name)
    );
  };


  const setHivSymptomsChangesOnChange = () => {
    const optionsb: string[] = [];
    hivSymptoms.forEach((hivSym) => {

      if (!optionsb.includes(hivSym)) {
        optionsb.push(hivSym);
      }
    });
    seData([...optionsb]);

  }



  const handleOtherChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setOtherState: React.Dispatch<React.SetStateAction<string>>,
    stateSetter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const { value } = e.target;
    setOtherState(value);
    stateSetter(prev => {
      const filteredPrev = prev.filter(item => !item.startsWith('Other'));
      return value ? [...filteredPrev, `Other: ${value}`] : filteredPrev;
    });
  };
  const displayotherInput = () => {
    
    return hivSymptoms.find(options =>  options?.toLocaleLowerCase()?.includes('other'))
  }

  useEffect(() => {
    setHivSymptomsChangesOnChange()
  }, [hivSymptoms]);


  return (
    <div className='current-hiv-information m-0 '>

      <div className="section">
        <h3>{title}</h3>
        <div>
          {options.map(option => (
            <div key={option.value}>
              <label>
                <input
                  type="checkbox"
                  name={option.value}
                  checked={hivSymptoms.includes(option.value)}
                  onChange={e => handleCheckboxChange(e)}
                />
                {option.label}
              </label>
            </div>
          ))}
          {displayotherInput() && (
            <input
              type="text"
              value={otherHivSymptom}
              onChange={e => handleOtherChange(e, setOtherHivSymptom, setHivSymptoms)}
              placeholder="Please specify"
            />
          )}

        </div>
      </div>
    </div>
  );
};

type radioText = {
  id: number,
  text: string
}
type radioBox = {
  options: radioText[]
  title: string
  setData: (selected: string) => void
  selected_option: string
}



export const RadioInput: React.FC<radioBox> = ({ options, title, setData,selected_option }) => {
  const [hivStatus, setHivStatus] = useState<string | null>(selected_option ? selected_option:null);


  useEffect(() => {
    setData(hivStatus as string)
  }, [hivStatus, setData])
  return (
    <div className='current-hiv-information m-0'>
      <div className="section">
        <h3>{title}</h3>

        {options.map(option => {
          return <label key={option.id}>
            <input
              type="radio"
              value={option.text}
              checked={hivStatus === option.text}
              onChange={() => setHivStatus(option.text)}
            />
            {option.text}
          </label>
        })}

      </div>

    </div>
  )
}

type datainput = {
  setDate: (selectedDate: string) => void;
  selectedTestDate: string;
};

export const DateInput: React.FC<datainput> = ({ setDate, selectedTestDate }) => {
  console.log(typeof selectedTestDate); // Check the type of selectedTestDate for debugging

  // Ensure selectedTestDate is a string before attempting to split it
  const [selectedday, selectedmonth, selectedyear] = typeof selectedTestDate === 'string' && selectedTestDate.includes("-")
    ? selectedTestDate.split("-")
    : ["", "", ""];

  const [lastHivTest, setLastHivTest] = React.useState({
    month: selectedmonth ?? '',
    day: selectedday ?? '',
    year: selectedyear ?? '',
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const handleDateChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    const { value } = e.target;
    setLastHivTest((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const generateDays = (month: string, year: string) => {
    const daysInMonth = new Date(Number(year), months.indexOf(month) + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
  };

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const days = lastHivTest.month && lastHivTest.year
    ? generateDays(lastHivTest.month, lastHivTest.year)
    : [];

  React.useEffect(() => {
    setDate(`${lastHivTest.day}-${lastHivTest.month}-${lastHivTest.year}`);
  }, [lastHivTest, setDate]);

  const years = Array.from({ length: currentYear - 2008 }, (_, i) => (2009 + i).toString());

  // Filter days, months, and years to prevent future dates
  const filteredMonths = lastHivTest.year === currentYear.toString()
    ? months.slice(0, currentMonth + 1)
    : months;

  const filteredDays = lastHivTest.year === currentYear.toString() && lastHivTest.month === months[currentMonth]
    ? days.slice(0, currentDay)
    : days;

  return (
    <div className="current-hiv-information m-0">
      <h2 className="hiv-inf-h2">When was your last HIV test?</h2>
      <div className="section">
        <div className="date-selectors">
          <select
            value={lastHivTest.year}
            onChange={(e) => handleDateChange(e, "year")}
          >
            <option value="">Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            value={lastHivTest.month}
            onChange={(e) => handleDateChange(e, "month")}
          >
            <option value="">Month</option>
            {filteredMonths.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <select
            value={lastHivTest.day}
            onChange={(e) => handleDateChange(e, "day")}
          >
            <option value="">Day</option>
            {filteredDays.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};



