import React,{useState,useEffect} from 'react';
import axios from 'axios';
import GetStarted from '../../assests/icons/Getstarted.svg';
import '../../assests/styles/request-lab-service.css';
import {StepProps} from './stepsProps';
import useStates from 'hooks/useStates';
import { useNavigate } from 'react-router-dom';

type Location = {
 
  city:string,
  area:string,
 
};


const RequestLabService1: React.FC<StepProps> = ({ onNext })  => {

  const {first_name,district,sub_county,village} = useStates()
  const navigate = useNavigate();

  const handleAddLocationClick = () => {
    navigate('/add-details')
  }
  return (
    <div className="request-lab-container">
      <div className='welcome'>Welcome, {first_name || null}</div>
      <div>
        <p>
          Easily request lab tests, view your results, and track ongoing tests from the comfort of your home. Our mobile lab services ensure convenience and privacy for all your healthcare needs.
        </p>
      </div>
      <div className="heading2">Confirm your location</div>
      <p>
        Before you proceed, kindly ensure your health status is fully
         up-to-date, reflecting all recent developments and new information. <span>Update Here</span>
      </p>
      <div className="location-card">
        <div>
        <div className='loc1' onClick={district ? undefined : handleAddLocationClick}>
            {district || <span className="add-location">Add</span>}
          </div>
          <div className='loc2'>{sub_county || "Physical"}, {village || "Address"}</div>
        </div>
        <div>
          <button onClick={onNext} className="get-started">Get Started <img src={GetStarted}  alt="get started" /> </button>
        </div>
      </div>
      <p className="note">
        <span>Note:</span> If this is a medical emergency, please dial 0800500300
      </p>
    </div>
  );
};

export default RequestLabService1;
