import React, { useState } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import RequestRefill from "./RequestRefill";
export default function PrescriptionTable() {
  const [viewDetails, SetViewDetails] = useState(false);
  const [refillRequest, setRefillRequest] = useState(false);
  return (
    <div className=" relative bg-[#fff]">
      <div className="border-l-4 border-[#8A3FFC] p-2 md:p-4">
        <div className="flex ">
          <div className="flex justify-center mt-4 md:mt-0 md:items-center">
            <h3 className="md:text-[25px] text-[20px] font-semibold text-[#B98CFD] topographyText">
              Rx
            </h3>
          </div>
          <div className="md:flex">
            <div className="flex justify-between ">
              <div className="flex flex-col md:border-r-[1px] md:border-[#D9D9D9]  items-center ">
                <div className="flex flex-col  p-2">
                  <h3 className="text-[#000] topographyText md:text-[18px] text-[12px] font-bold">
                    Aspirin 500 mg oral tablet
                  </h3>
                  <p className="md:w-[270px] w-[175px] topographyText md:text-[15px] text-[12px] font-[400] text-[#000]">
                    1 tablet(s) once a day after a meal for 10 Day(s), oral
                    route
                  </p>
                </div>
              </div>
              <div className="flex flex-col md:border-r-[1px] md:border-[#D9D9D9] justify-center">
                <div className="px-4 flex flex-col justify-center  gap-1">
                  <p className="topographyText text-[15px] font-[400] flex gap-1 ">
                    Refills:<span className="font-[600]">3</span>
                  </p>
                  <p className="topographyText text-[15px] font-[400] flex gap-1">
                    Dependency<span className="font-[600]">10</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col justify-center">
                <p className="topographyText  flex gap-1 md:text-[15px] text-[12px]">
                  Prescribed on:<span className="font-[600]">May 20, 2025</span>
                </p>
                <p className="topographyText  flex gap-1 md:text-[15px] text-[12px]">
                  Prescribed by:
                  <span className="font-[600]">Dr. Mike Tusiime</span>
                </p>
              </div>
              <div
                className="flex flex-col justify-center p-2 "
                onClick={() => SetViewDetails((pre) => !pre)}
              >
                <p className="topographyText text-[#3DD6DB] mb-2 text-[14px] hidden md:block">
                  View details
                </p>
                <span className="w-full text-center flex justify-center  text-[#3DD6DB]">
                  {" "}
                  <BsChevronDoubleDown className="w-[30px] h-[30px]" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {viewDetails && (
          <div className="md:ml-17 flex flex-col md:flex-row gap-4 mt-8 transition ease-in-out delay-1000">
            <div className="md:w-[50%] flex flex-col gap-2 w-full">
              <p className="topographyText  flex gap-1 text-[15px] font-[500]">
                Prescription Date:
                <span className="font-[400]">Fri, 24 Jun 2024</span>
              </p>
              <p className="topographyText  flex gap-1 text-[18px]  font-[500] ">
                Provider:<span className="font-[400]">YYY Pharmacy</span>
              </p>
              <p className="topographyText  flex gap-1 text-[18px] font-[500]">
                Consumption Method :<span> Oral</span>
              </p>
              <p className="topographyText  flex gap-1 text-[18px] font-[500]">
                Dispense Quantity :
                <span className="font-[400]">10 Tablets</span>
              </p>
              <p className="topographyText  flex gap-1 text-[18px] font-[500]">
                Refills Used:<span className="font-[400]">1</span>
              </p>
              <p className="topographyText  flex gap-1 text-[18px] font-[500]">
                Refills Remining: <span className="font-[400]">2</span>
              </p>
              <p className="topographyText  flex gap-1 text-[18px] font-[500]">
                Expecting Date to end :{" "}
                <span className="font-[400]">Mon, 20 Aug 2024</span>
              </p>
            </div>
            <div className="flex justify-center items-center w-[50%]">
              <button
                className="bg-[#8A3FFC] p-2 text-[#fff] topographyText font-[600] rounded-[6px] "
                onClick={() => setRefillRequest((pre) => !pre)}
              >
                Request Refill
              </button>
            </div>
          </div>
        )}
      </div>
      {refillRequest && (
        <div className=" w-full  absolute inset-0 flex justify-center items-center top-[-40%]">
          <RequestRefill />
        </div>
      )}
    </div>
  );
}
