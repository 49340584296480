import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Registertypes } from "./types";
import { user } from "Features/auth/types";
import { errorType } from "./types";
import { patientInformation } from "views/patients/pages/MorePatientInformation/infoTypes";
const initialRegesterState: Registertypes = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",

  username: "",

  password_confirmation: "",
  verification_code: 0,
  phone_number: ''
};

const initialPatientInformation: patientInformation = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  country: "",
  city: "",
  gender: "",
  whatsappNumber: "",
  dateOfBirth: "",
  allergies: [],
  medication: [],
  keypopulation: "",
  subCounty: "",
  village: "",
  emergencyContact: "",
  emergencyContactNumber: "",
  emergencyContactEmail: "",
  emergencyContactRelationship: "",
};

export default function useForm(validateOnChange = false, auth?: string) {
  // const dispatch = useDispatch();
  const [registerValues, setRegisterValues] =
    React.useState<Registertypes>(initialRegesterState);
  const [patientInformation, setpatientInformation] =
    React.useState<patientInformation>(initialPatientInformation);
  const [errors, setErrors] = React.useState<errorType>({});
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setErrors({ ...errors, ...{ [name]: "", username: "", invalid_code: "", custom_error: "" } });

    setRegisterValues((prevValues) => {
      // Create a copy of the previous state
      const updatedValues = { ...prevValues, [name]: value };

      if (name === "first_name" || name === "last_name") {
        updatedValues.username = generateUsername(
          updatedValues.first_name,
          updatedValues.last_name
        );
      }

      return updatedValues;
    });
  };

  const generateUsername = (first_name: string, last_name: string) => {
    if (first_name && last_name) {
      return last_name.charAt(0).toLowerCase() + first_name.toLowerCase();
    }
    return "";
  };

  const isStageOneValidated = () => {
    const temp: errorType = {};
    var status = true;

    // console.log(registerValues.phone_number, 'phone number....')

    if (registerValues.username.length < 6) {
      temp.username = "Username must be at least 6 characters";
      status = false;
    }

    if (!registerValues.first_name) {
      temp.first_name = "First name is required";
      status = false;
    }

    if (!registerValues.phone_number) {
      temp.phone_number = "Phone number is required";
      status = false;
    }

    if (!registerValues.last_name) {
      temp.last_name = "Last name is required";
      status = false;
    }

    // if (registerValues.username.length < 6) {
    //   temp.username = "Username must be at least 6 characters";
    //   status = false;
    // }

    setErrors({ ...temp });

    return status;
  };

  const setRegisterErrors = (key: string, error: string) => {
    setErrors({ ...errors, ...{ [key]: error } });
  };

  const setFormSubmitStatus = (is_form_submitted: boolean) => {
    setRegisterValues({ ...registerValues, ...{ is_form_submitted } });
  };

  const isStageTwoValidated = () => {
    const temp: errorType = {};
    var status = true;

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Password validation
    if (!registerValues.password) {
      temp.password = "Password is required";

      status = false;
    } else if (registerValues.password.length < 10) {
      temp.password = "Password should contain at least 10 characters";
      status = false;
    } else if (!/[A-Z]/.test(registerValues.password)) {
      temp.password = "Password should contain at least one uppercase letter";
      status = false;
    } else if (!/[0-9]/.test(registerValues.password)) {
      temp.password = "Password should contain at least one number";
      status = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(registerValues.password)) {
      temp.password = "Password should contain at least one special character";
      status = false;
    }

    // Confirm password validation
    if (registerValues.password !== registerValues.password_confirmation) {
      temp.password_confirmation = "Passwords do not match";
      status = false;
    }

    temp.email = emailRegex.test(registerValues.email || "")
      ? ""
      : "Invalid email format";

    setErrors({ ...temp });

    return status;
  };
  const validateRegister = (stage: number) => {
    if (stage === 1) {
      return isStageOneValidated();
    }

    if (stage === 2) {
      return isStageTwoValidated();
    }
    if (stage === 3) {
      return isStageTwoValidated();
    }
  };
  const patienthandlechange = (e: any) => {
    const { name, value } = e.target;
    setpatientInformation((prevValues: any) => {
      if (name === "medication" || name === "allergies") {
        const updatedValues = {
          ...prevValues,
          [name]: [...prevValues[name], value],
        };
        return updatedValues;
      } else {
        const updatedValues = { ...prevValues, [name]: value };
        return updatedValues;
      }
    });
  };

  return {
    handleChange,
    validateRegister,
    patienthandlechange,
    patientInformation,
    setpatientInformation,
    errors,
    setErrors,
    registerValues,
    setRegisterErrors,
    setFormSubmitStatus,
  };
}
