import React from "react";

export default function MessageRecieved() {
  return (
    <div>
      <div className="p-6">
        <div className="bg-[#E6DAFF] w-[270px] h-[62px] p-2 rounded-t-[20px] rounded-l-lg ro ">
          <h3 className="text-[#000] ">
            Am good.... How about you?. How are you?
          </h3>
        </div>
        <div className="flex">
          <h4 className="topographyText text-[15px] font-[400] text-[#596780]">
            Jun 7, 2024, 12:35Am
          </h4>
        </div>
      </div>
    </div>
  );
}
