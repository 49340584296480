import { useDispatch } from "react-redux";
import { createHivCareService } from "Features/app/RequestLabService/Hivcare.actions";
import {
  setPreExposureProphylaxis,
  setHealthProducts,

  setAntiretroviralTherapy,
  setMentalHealthServices,
  setPostExposureProphylaxis,
} from "Features/app/RequestLabService/HivCare.slice";


export default function useHivCare() {
  const GetPrepExposeProphylaxis = {
    PREPcurrentHivInformatio(
      dispatch: ReturnType<typeof useDispatch>,
      symptoms: any,
      status: any,
      testDate: any,
      file: any,
    ) {


      dispatch(
        setPreExposureProphylaxis({
          type: "related_symptoms",
          value: symptoms,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "hiv_status",
          value: status,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "last_test_date",
          value: testDate,
        })
      );
      // dispatch(
      //   setPreExposureProphylaxis({
      //     type: "report_file_uri",
      //     value: file,
      //   })
      // );
    },
    PREPexperience(
      dispatch: ReturnType<typeof useDispatch>,
      adherence: any,
      sideEffects: any,
      prepExperience: any
    ) {


      dispatch(
        setPreExposureProphylaxis({
          type: "adherence_history",
          value: adherence,
        })
      );


      dispatch(
        setPreExposureProphylaxis({
          type: "prep_before",
          value: prepExperience,
        })
      );

    },

    PrepCurrentHealthStatus(
      dispatch: ReturnType<typeof useDispatch>,
      medications: any,
      healthIssues: any,
      healthConditions: any,
      isPregnant: string | null // Add this line
    ) {



      dispatch(
        setPreExposureProphylaxis({
          type: "medications",
          value: medications,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "healthIssues",
          value: healthIssues,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "health_conditions",
          value: healthConditions,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "pregnancy_status",
          value: isPregnant,
        })
      );
    },

    PrePRequestDetails(
      dispatch: ReturnType<typeof useDispatch>,
      preferredMedication: any,
      deliveryMethod: any,

      selectedFacilityId: any,
      selectedDeliveryDate: any,
      selectedDeliveryTime: any,
      testDate: any,
      selectedFile: any
    ) {
      dispatch(
        setPreExposureProphylaxis({
          type: "prefered_prep_medication",
          value: preferredMedication,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "result_delivery_method",
          value: deliveryMethod,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "facility_id",
          value: selectedFacilityId,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "preferred_date",
          value: selectedDeliveryDate,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "preferred_time",
          value: selectedDeliveryTime,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "last_test_date",
          value: testDate,
        })
      );
      dispatch(
        setPreExposureProphylaxis({
          type: "report_file_uri",
          value: selectedFile,
        })
      );
    },
    getRequest(dispatch: ReturnType<typeof useDispatch>, formData: any) {
      const PostRequest = {
        facility_id: formData.facility_id,
        health_issues: formData.health_issues,
        health_conditions: formData.health_conditions,
        last_test_date: formData.last_test_date,
        report_file_uri: formData.report_file_uri,
        medication_length: formData.medication_length,
        medication_taken: formData.medication_taken,
        medications: formData.medications,
        prefered_prep_medication: formData.prefered_prep_medication,
        preferred_date: formData.preferred_date,
        preferred_time: formData.preferred_time,
        prep_before: formData.prep_before,
        related_symptoms: formData.related_symptoms,
        adherence_history: formData.adherence_history,
        result_delivery_method: formData.result_delivery_method,
        sample_collection_location: "kampala",
        service_type: "PREP_S",
      };

      dispatch(createHivCareService(PostRequest) as any);
    },
  };
  const GetHealthproducts = {
    pepCurrentHealth(
      dispatch: ReturnType<typeof useDispatch>,
      currentMedications: any,
      existingConditions: any,
      recentHealthIssues: any
    ) {
      dispatch(
        setHealthProducts({
          type: "health_issues",
          value: recentHealthIssues,
        })
      );
      dispatch(
        setHealthProducts({
          type: "health_conditions",
          value: existingConditions,
        })
      );
      dispatch(
        setHealthProducts({
          type: "medications",
          value: currentMedications,
        })
      );
    },

    pepBrandtypes(
      dispatch: ReturnType<typeof useDispatch>,
      selectedCondoms: any,
      selectedLubricants: any,
      selectedNeedles: any,
      selectedMedications: any,
      selectedHivTestKits: any,
      selectedEmergencyContraceptives: any,
      selectedPainReliefMedication: any
    ) {
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_condoms",
          value: selectedCondoms,
        })
      );
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_lubricants",
          value: selectedLubricants,
        })
      );
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_clean_needles",
          value: selectedNeedles,
        })
      );
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_sti_treatment",
          value: selectedMedications,
        })
      );
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_self_test_kits",
          value: selectedHivTestKits,
        })
      );
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_emergency_concentraptive",
          value: selectedEmergencyContraceptives,
        })
      );
      dispatch(
        setHealthProducts({
          type: "prefered_brand_type_for_pain_relief_medication",
          value: selectedPainReliefMedication,
        })
      );
    },

    HealthUsage_Experience(
      dispatch: ReturnType<typeof useDispatch>,
      hasUsedProducts: any,
      usedProducts: any,
      sideEffects: any
    ) {
      dispatch(
        setHealthProducts({
          type: "usage_experience_products_usage",
          value: hasUsedProducts ? 'Yes' : hasUsedProducts,
        })
      );
      dispatch(
        setHealthProducts({
          type: "usage_experience_which_product",
          value: usedProducts,
        })
      );
      dispatch(
        setHealthProducts({
          type: "usage_experience_product_side_effect",
          value: sideEffects,
        })
      );
    },

    healthDeliveryDetails(
      dispatch: ReturnType<typeof useDispatch>,
      deliveryMethod: any,
      date: any,
      preferedTime: any,
      additionalInfo: any,
      facility_id: any
    ) {
      dispatch(
        setHealthProducts({
          type: "facility_id",
          value: facility_id,
        })
      );
      dispatch(
        setHealthProducts({
          type: "additional_information",
          value: additionalInfo,
        })
      );
      dispatch(
        setHealthProducts({
          type: "preferred_date",
          value: date,
        })
      );
      dispatch(
        setHealthProducts({
          type: "preferred_time",
          value: preferedTime,
        })
      );
      dispatch(
        setHealthProducts({
          type: "result_delivery_method",
          value: deliveryMethod,
        })
      );
    },

    HpProductsRequest(
      dispatch: ReturnType<typeof useDispatch>,
      productsNeeded: any,
      quantities: any
    ) {
      dispatch(
        setHealthProducts({
          type: "products_needed",
          value: productsNeeded,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_condoms",
          value: quantities.Condoms,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_lubricants",
          value: quantities.Lubricants,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_clean_needles",
          value: quantities.Clean_Needles
          ,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_sti_treatment",
          value: quantities.STI_Treatment_Medications,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_self_test_kits",
          value: quantities.HIV_Self_Test_Kits,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_emergency_concentraptive",
          value: quantities.Emergency_Contraceptives,
        })
      );
      dispatch(
        setHealthProducts({
          type: "quantity_required_for_pain_relief_medication",
          value: quantities.Pain_Relief_Medication,
        })
      );
    },

    posthealthProducts(
      dispatch: ReturnType<typeof useDispatch>,
      formData: any
    ) {
      const PostRequest = {
        health_issues: formData.health_issues,
        medications: formData.medications,
        health_conditions: formData.health_conditions,
        products_needed: formData.products_needed,
        quantity_required_for_condoms: formData.quantity_required_for_condoms ? formData.quantity_required_for_condoms : '',
        quantity_required_for_lubricants: formData.quantity_required_for_lubricants ? formData.quantity_required_for_lubricants : '',
        quantity_required_for_clean_needles: formData.quantity_required_for_clean_needles ? formData.quantity_required_for_clean_needles : '',
        quantity_required_for_sti_treatment: formData.quantity_required_for_sti_treatment ? formData.quantity_required_for_sti_treatment : '',
        quantity_required_for_self_test_kits: formData.quantity_required_for_self_test_kits ? formData.quantity_required_for_self_test_kits : '',
        quantity_required_for_emergency_concentraptive: formData.quantity_required_for_emergency_concentraptive ? formData.quantity_required_for_emergency_concentraptive : '',
        quantity_required_for_pain_relief_medication: formData.quantity_required_for_pain_relief_medication ? formData.quantity_required_for_pain_relief_medication : '',
        prefered_brand_type_for_condoms: formData.prefered_brand_type_for_condoms,
        prefered_brand_type_for_lubricants: formData.prefered_brand_type_for_lubricants,
        prefered_brand_type_for_clean_needles: formData.prefered_brand_type_for_clean_needles,
        prefered_brand_type_for_sti_treatment: formData.prefered_brand_type_for_sti_treatment,
        prefered_brand_type_for_self_test_kits: formData.prefered_brand_type_for_self_test_kits,
        prefered_brand_type_for_emergency_concentraptive: formData.prefered_brand_type_for_emergency_concentraptive,
        prefered_brand_type_for_pain_relief_medication: formData.prefered_brand_type_for_pain_relief_medication,
        usage_experience_products_usage: formData.usage_experience_products_usage,
        usage_experience_which_product: formData.usage_experience_which_product,
        usage_experience_product_side_effect: formData.usage_experience_product_side_effect,
        facility_id: formData.facility_id,
        result_delivery_method: formData.result_delivery_method,
        preferred_date: formData.preferred_date,
        preferred_time: formData.preferred_time,
        additional_information: formData.additional_information,
        service_type: "HEALTH_PRODUCT_S", // Add this if needed for Health Products
      };
      //console.log("post requests", PostRequest);
      dispatch(createHivCareService(PostRequest) as any);
    },
  };


  const GetArtTreatment = {
    artHivStatus(
      dispatch: ReturnType<typeof useDispatch>,
      cd4Count: any,
      viralLoad: any,
      healthIssues: any
    ) {
      dispatch(
        setAntiretroviralTherapy({
          type: "cd4_count",
          value: cd4Count,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "viral_load",
          value: viralLoad,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "health_issues",
          value: healthIssues,
        })
      );
    },
    artTreatment(
      dispatch: ReturnType<typeof useDispatch>,
      artStatus: any,
      medications: any
    ) {
      dispatch(
        setAntiretroviralTherapy({
          type: "viral_load",
          value: artStatus,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "other_medications",
          value: medications,
        })
      );
    },
    pastArtDetails(
      dispatch: ReturnType<typeof useDispatch>,
      artRegimens: any,
      currentStatus: any,
      adherence: any,
      sideEffects: any
    ) {
      dispatch(
        setAntiretroviralTherapy({
          type: "past_art_adherence_history",
          value: adherence,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "past_art_prev_art_regiment",
          value: artRegimens,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "past_art_art_status",
          value: currentStatus,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "past_art_adherence_history",
          value: adherence,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "past_art_side_eefects",
          value: sideEffects,
        })
      );
    },
    artPreferedDetails(
      dispatch: ReturnType<typeof useDispatch>,
      preferredRegimen: any,
      dosageDuration: any,
      deliveryMethod: any,
      facilityRelationship: any,
      facilityId: any,
      date: any,
      time: any,
      additional_info: any
    ) {
      dispatch(
        setAntiretroviralTherapy({
          type: "prefered_art_regiment",
          value: preferredRegimen,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "prefered_dosage_duration",
          value: dosageDuration,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "delivery_method",
          value: deliveryMethod,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "preferred_date",
          value: deliveryMethod,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "preferred_time",
          value: deliveryMethod,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "facility_rel_status",
          value: facilityRelationship,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "facility_id",
          value: facilityId,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "preferred_date",
          value: date,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "preferred_time",
          value: time,
        })
      );
      dispatch(
        setAntiretroviralTherapy({
          type: "additional_information",
          value: additional_info,
        })
      );
    },
    postArtForm(dispatch: ReturnType<typeof useDispatch>, formData: any) {
      const PostRequest = {
        cd4_count: formData.cd4_count,
        viral_load: formData.viral_load,
        health_issues: formData.health_issues,
        taking_art: formData.taking_art,
        other_medications: formData.other_medications,
        past_art_prev_art_regiment: formData.past_art_prev_art_regiment,
        past_art_art_status: formData.past_art_art_status,
        past_art_adherence_history: formData.past_art_adherence_history,
        prefered_art_regiment: formData.prefered_art_regiment,
        prefered_dosage_duration: formData.prefered_dosage_duration,
        delivery_method: formData.delivery_method,
        facility_rel_status: formData.facility_rel_status,
        additional_information: formData.additional_information,
        facility_id: formData.facility_id,
        sample_collection_location: formData.sample_collection_location,
        result_delivery_method: formData.result_delivery_method,
        preferred_date: formData.preferred_date,
        preferred_time: formData.preferred_time,
        service_type: "ANTIRE_THEREPY_S", // Add this if needed for ART
      };

      dispatch(createHivCareService(PostRequest) as any);
    },
  };
  const GetMentalHealth = {
    mentalHealthStatus(
      dispatch: ReturnType<typeof useDispatch>,
      currentHealthConcerns: any,
      rencentSystoms: any
    ) {
      dispatch(
        setMentalHealthServices({
          type: "mental_health_issues",
          value: currentHealthConcerns,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "mental_experienced_symptoms",
          value: rencentSystoms,
        })
      );
    },
    MHSMentalHealthHistory(
      dispatch: ReturnType<typeof useDispatch>,
      mentalHealthTreatment: any,
      typesOfTreatment: any,
      mentalHealthConditions: any,
      psychiatricMeds: any,
      medicationList: any
    ) {
      dispatch(
        setMentalHealthServices({
          type: "prev_mental_treatment",
          value: mentalHealthTreatment,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "treatment_received",
          value: typesOfTreatment,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "treatment_received",
          value: typesOfTreatment,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "health_conditions",
          value: mentalHealthConditions,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "current_taking_medication",
          value: psychiatricMeds,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "medication_lists",
          value: medicationList,
        })
      );
    },
    mentalHealthStatus2(
      dispatch: ReturnType<typeof useDispatch>,
      healthIssues: any,
      medications: any,
      healthConditions: any
    ) {
      dispatch(
        setMentalHealthServices({
          type: "health_issues",
          value: healthIssues,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "mental_health_condition",
          value: healthConditions,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "medications",
          value: medications,
        })
      );
    },
    mentalhealthpreferences(
      dispatch: ReturnType<typeof useDispatch>,
      serviceNeeds: any,
      date: any,
      facility_id: any,
      preferedTime: any,
      additionalInfo: any,
      deliveryMode: any
    ) {
      dispatch(
        setMentalHealthServices({
          type: "result_delivery_method",
          value: deliveryMode,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "mental_health_services",
          value: serviceNeeds,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "preferred_time",
          value: preferedTime,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "additional_information",
          value: additionalInfo,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "facility_id",
          value: facility_id,
        })
      );
      dispatch(
        setMentalHealthServices({
          type: "preferred_date",
          value: date,
        })
      );
    },
    PostMentalHealthForm(
      dispatch: ReturnType<typeof useDispatch>,
      formData: any
    ) {
      const PostRequest = {
        mental_health_issues: formData.mental_health_issues,
        mental_experienced_symptoms: formData.mental_experienced_symptoms,
        prev_mental_treatment: formData.prev_mental_treatment,
        treatment_received: formData.treatment_received,
        facility_id: formData.facility_id,
        sample_collection_location: formData.sample_collection_location,
        result_delivery_method: formData.result_delivery_method,
        preferred_date: formData.preferred_date,
        preferred_time: formData.preferred_time,
        facility_rel_status: formData.facility_rel_status,
        additional_information: formData.additional_information,
        medication_lists: formData.medication_lists,
        side_effects: formData.side_effects,
        mental_health_condition: formData.mental_health_condition,
        current_taking_medication: formData.current_taking_medication,
        medications: formData.medications,
        mental_health_services: formData.mental_health_services,
        health_conditions: formData.health_conditions,
        health_issues: formData.health_issues,
        service_type: "MENTAL_HEALTH_S", // Add or modify this if needed for mental health services
      };

      dispatch(createHivCareService(PostRequest) as any);
    },
  };
  const GetPEPServices = {
    pepCurrenthivinformation(
      dispatch: ReturnType<typeof useDispatch>,
      hivSymptoms: any,
      hivStatus: any,
      date: any
    ) {
      dispatch(
        setPostExposureProphylaxis({
          type: "last_test_date",
          value: date,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "related_symptoms",
          value: hivSymptoms,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "hiv_status",
          value: hivStatus,
        })
      );
    },
    PepCurrentHealthStatus(
      dispatch: ReturnType<typeof useDispatch>,
      healthIssues: any,
      medications: any,
      conditions: any,
      pregnant: any
    ) {
      dispatch(
        setPostExposureProphylaxis({
          type: "health_issues",
          value: healthIssues,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "medications",
          value: medications,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "health_conditions",
          value: conditions,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "pregnancy_status",
          value: pregnant,
        })
      );
    },
    pepExposureinformation(
      dispatch: ReturnType<typeof useDispatch>,
      exposureType: any,
      details: any,
      date: any
    ) {
      dispatch(
        setPostExposureProphylaxis({
          type: "exposer_types",
          value: exposureType,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "exposer_details",
          value: details,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "exposer_date",
          value: date,
        })
      );
    },
    pepTreatmentHistory(
      dispatch: ReturnType<typeof useDispatch>,
      takenPEPBefore: any,
      medicationTaken: any,
      durationOfPEP: any,
      reasonsForStartingPEP: any,
      sideEffects: any
    ) {
      dispatch(
        setPostExposureProphylaxis({
          type: "prep_before",
          value: takenPEPBefore,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "side_effects",
          value: sideEffects,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "medication_taken",
          value: medicationTaken,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "medication_length",
          value: durationOfPEP,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "reason_for_pep",
          value: reasonsForStartingPEP,
        })
      );

    },
    pepPreferences(
      dispatch: ReturnType<typeof useDispatch>,
      preferredMedication: any,

      deliveryMethod: any,
      facilityStatus: any,

      selectedFacilityId: any,
      selectedDeliveryDate: any,
      selectedDeliveryTime: any,
      testDate: any,
      selectedFile: any


    ) {

      dispatch(
        setPostExposureProphylaxis({
          type: "preferred_time",
          value: selectedDeliveryTime,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "facility_id",
          value: selectedFacilityId,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "preferred_date",
          value: selectedDeliveryDate,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "report_file_uri",
          value: selectedFile,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "last_test_date",
          value: testDate,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "result_delivery_method",
          value: deliveryMethod,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "prefered_prep_medication",
          value: preferredMedication,
        })
      );
      dispatch(
        setPostExposureProphylaxis({
          type: "facility_rel_status",
          value: facilityStatus,
        })
      );

    },
    PostPepDetails(dispatch: ReturnType<typeof useDispatch>, formData: any) {
      const PostRequest = {
        related_symptoms: formData.related_symptoms,
        hiv_status: formData.hiv_status,

        health_issues: formData.health_issues,
        medications: formData.medications,
        health_conditions: formData.health_conditions,
        pregnancy_status: formData.pregnancy_status,
        exposer_date: formData.exposer_date,
        exposer_types: formData.exposer_types,
        exposer_details: formData.exposer_details,
        prep_before: formData.prep_before,
        medication_taken: formData.medication_taken,
        medication_length: formData.medication_length,
        last_test_date: formData.last_test_date,
        side_effects: formData.side_effects,
        prefered_prep_medication: formData.prefered_prep_medication,
        facility_id: formData.facility_id,
        sample_collection_location: formData.sample_collection_location,
        result_delivery_method: formData.result_delivery_method,
        preferred_date: formData.preferred_date,
        preferred_time: formData.preferred_time,
        facility_rel_status: formData.facility_rel_status,
        additional_information: formData.additional_information,
        service_type: "PEP_S",
      };

      dispatch(createHivCareService(PostRequest) as any);
    },
  };
  return {
    GetPrepExposeProphylaxis,
    GetHealthproducts,
    GetArtTreatment,
    GetMentalHealth,
    GetPEPServices,
  };
}