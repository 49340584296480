import React from "react";
import { useState } from "react";
import CloseIcon from "../../assests/icons/close-icon.svg";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import { useNavigate } from "react-router-dom";
import useStates from "hooks/useStates";
type NotEligible = {
  title: string | null;
  tip: string | null;
  CTA: string | null;
};
const NotEligible: React.FC<StepProps & NotEligible> = ({
  onPrevious,
  title,
  tip,
  CTA,
}) => {
  const GotoHome = useNavigate();
  const [isTipVisible, setIsTipVisible] = useState(true); //this state is for managing the tip visibility
  const handleSubmit = (e: any) => {
    e.preventDefault();
    GotoHome("/dashboard") as any;
  };
  const handleCloseTip = () => {
    setIsTipVisible(false); // Hide tip container when close icon is clicked
  };
  const {first_name} = useStates()  
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something ?
        </div>
      </div>
      <div className="heading2">Stay Informed Today , {first_name ??""}</div>

      <p>{title}</p>

      {isTipVisible && (
        <div className="tip-container">
          <button className="close-tip" onClick={handleCloseTip}>
            <img className="img-close-tip" src={CloseIcon} alt="close" />
          </button>
          <h6 className="sel-note-heading">Tip</h6>

          <p className="sel-note">{tip}</p>
        </div>
      )}

      <div className="sel-test">
        <button onClick={handleSubmit}>{CTA}</button>
      </div>
    </div>
  );
};

export default NotEligible;
