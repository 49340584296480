import React from "react";

import WelcomeCard from "../../../../components/dasboardMain/WelcomeCard";
import HivServeLinks from "components/dasboardMain/HivServiceLinks";
import useStates from "hooks/useStates";
import { vistingSammaryData } from "./data/Data";
import { saved } from "assests/dashBoard";
import { EmergencyCall } from "assests/dashBoard";
import { medical,Ambulance } from "assests/dashBoard";
import "../../../../assests/styles/dashboard.css";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../../../Store';
import { setIsAuthenticated, setUserInfo } from '../../../../Features/auth/auth.slice';
import localStorageService from 'services/localStorageService';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Dashboard() {


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.auth.userInfo)

  const redirectUserTologin = async () => {

    // @ts-ignore
   
    if (!userInfo.email_verified_at) {
  

      await localStorageService.clearStorage('user');
      await dispatch(setUserInfo({}));
      await dispatch(setIsAuthenticated(false));
      navigate('/login');

    }

  }


  useEffect(() => {

    redirectUserTologin();

  }, [])

 

const {first_name, isUserInfoCompleted,email_verified_at,phone_verified_at} = useStates()  

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="welcome-section">
          <WelcomeCard 
            first_name={first_name || null} 
            email_verified_at={email_verified_at || null} 
            isUserInfoCompleted={isUserInfoCompleted()} 
            phone_verified_at={phone_verified_at} 
          />
        </div>
        <div className="hiv-serve-links-section">
          <HivServeLinks />
        </div>
      </div>
    </div>
  );
}