import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col md:flex-row items-center justify-center min-h-screen bg-gray-100 p-8">
      {/* Triangle at the Top */}
      <div className="absolute top-12 left-1/2 transform -translate-x-1/2 w-0 h-0 
        border-l-[40px] border-l-transparent border-r-[40px] border-r-transparent border-b-[40px] border-b-[#17bdb9]
        md:border-l-[50px] md:border-r-[50px] md:border-b-[50px]">
          
      </div>

      {/* Image Section */}
      <img
        src="https://via.placeholder.com/300x200?text=Not+Found" // Replace with your desired image URL
        alt="Not Found"
        className="w-64 h-64 mb-8 md:mb-0 md:mr-8 rounded-lg shadow-lg"
      />

      {/* Text Section */}
      <div className="text-center md:text-left">
        <h1 className="text-4xl font-extrabold text-[#17bdb9] mb-4">Oops... Page Not Found</h1>
        <p className="text-lg text-gray-600 mb-6">
          Sorry! The page you're looking for doesn't exist. Please go back or go to the homepage.
        </p>

        {/* Buttons Section */}
        <div className="flex flex-col md:flex-row gap-4">
          <button
            className="w-full bg-[#e7d7ff] text-[#8A3FFC] py-3 px-6 rounded-full border-2 border-[#8A3FFC] transition-colors duration-300 hover:bg-[#8A3FFC] hover:text-[#e7d7ff]"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
          <button
            className="w-full bg-[#e7d7ff] text-[#8A3FFC] py-3 px-6 rounded-full border-2 border-[#8A3FFC] transition-colors duration-300 hover:bg-[#8A3FFC] hover:text-[#e7d7ff]"
            onClick={() => navigate('/')}
          >
            Go to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
