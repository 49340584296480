import React, { useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ToastMessage {


    message: string,
    status: string


}
const ToastMessage: React.FC<ToastMessage> = ({ message, status }) => {

    const notify = () => {

        // @ts-ignore
        toast[status](message)
    };

    useEffect(() => {


        if (status && message) {
            notify()
        }
    }, [message, status])

    return (
        <div>

            <ToastContainer />
        </div>
    );
}
export default ToastMessage;
