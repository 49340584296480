import React, { useState, useEffect } from "react";
import { BackButton } from "components/shared/BackButton";
import { useNavigate } from "react-router-dom";
import BackNavigation from "components/shared/backNavigation";
import "../../assests/styles/request-lab-service.css";
import useRequestLabService from "hooks/useRequestLabService";
import useStates from "hooks/useStates";
import { useDispatch, useSelector } from "react-redux";
import { createRequestLabService } from "Features/app/RequestLabService/RequestLabServiceActions";
import OnSuccessPage from "components/RequestLabService/OnSuccessPage";
import { createHivCareService } from "Features/app/RequestLabService/Hivcare.actions";
import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
import {
  setFormStatus,
  setCreateLoading,
} from "Features/app/RequestLabService/requestLabServices.slice";
import Loader from "components/shared/Loader";

const TextBgStyle = {
  backgroundColor: "linear-gradient(90deg, #8A3FFC 0%, #3DD6DB 100%))",
};

interface DateDisplayProps {
  date: string;
  format: "long" | "short";
}

type HivTestingPatientDetailstypes = {
  onNext: () => void;
  onPrevious: Function;
  reasonsFortheService: string | null;
  preferences: string | null;
  typeOfService: string | null;
  GetFrom?: string | null;
  GetTo?: string | null;
};

const formatDate = (dateString: string, format: "long" | "short"): string => {
  const date = new Date(dateString);

  if (format === "long") {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } else if (format === "short") {
    return date.toISOString().split("T")[0];
  }

  return dateString;
};

export default function ConfirmDetailsPage({
  onNext,
  onPrevious,
  preferences,
  reasonsFortheService,
  typeOfService,
  GetFrom,
  GetTo,
}: HivTestingPatientDetailstypes) {
  const [disPlaySuccess, setDisplaySucess] = useState<boolean>(false);
  const loading = useSelector((state: any) => state.hivCare.createLoadings);
  const isSuccess = useSelector((state: any) => state.hivCare.createSuccess);
  const [consent, setConsent] = useState(false);
  const [consentError, setConscentError] = useState(false);
  const {
    email,
    first_name,
    last_name,

    district,
    phone_number,
    sub_county,
    gender,
    whatsap_number,
    patientServiceFormStatus,
    getFacilities_data,
    create_loading,
    create_error,
  } = useStates();
  const { lapServiseState } = useRequestLabService();
  const postRequestService = useDispatch<any>();
  const { LabTestDone, selectedLabTest, collectSampleFrom, getResultsFrom } =
    lapServiseState;
  const goToDasboard = useNavigate();

  const handleConsentChange = () => {

    setConsent(!consent);
    setConscentError(false);

  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplaySucess(false);

      if (patientServiceFormStatus) {
        goToDasboard("/dashboard");
        postRequestService(setFormStatus(false));
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [patientServiceFormStatus, goToDasboard]);

  let facilityId = null;
  for (let i = 0; i < getFacilities_data?.length; i++) {
    if (getFacilities_data[i].name === LabTestDone?.SeletedOption) {
      facilityId = getFacilities_data[i].id;
      break;
    }
  }

  const requestLabServiceData = {
    facility_id: facilityId,
    additional_info: LabTestDone.AddedAdditionalInformation,
    preferred_date: LabTestDone.SeletedDateAndTime,
    preferred_time: LabTestDone.SeletedTime,
    sample_collection_location: district + "," + sub_county,
    result_delivery_method: getResultsFrom,
    service_type: "HIV_S"
  };

  const success = () => {

    if (!consent) {

      setConscentError(true);
      return;
    }

    postRequestService(createHivCareService(requestLabServiceData));
    setDisplaySucess(true);
  };



  return (
    <div className="hiv-testing-container">
      {!patientServiceFormStatus && (
        <div className="content-wrapper">
          <div className="navigation-wrapper">
            <BackNavigation goBack={onPrevious} title="Forgot something?" />
          </div>
          <div className="title-wrapper">
            <h3 className="title">
              Well done,
              <span className="highlight-text">{first_name || ""}</span>
            </h3>
            <p className="subtitle">
              Now confirm if we have captured your information correctly
            </p>
          </div>
          <div className="details-wrapper">
            <div className="details-section">
              <h3 className="section-title">Personal Details</h3>
              <section className="details-grid">
                <div className="label">First Name</div>
                <div className="value">{first_name || ""}</div>
              </section>
              <section className="details-grid">
                <div className="label">Last Name</div>
                <div className="value">{last_name || ""}</div>
              </section>
              <section className="details-grid">
                <div className="label">Gender</div>
                <div className="value">{gender || ""}</div>
              </section>
              {/* <section className="details-grid">
                <div className="label">District</div>
                <div className="value">{district || ""}</div>
              </section>
              <section className="details-grid">
                <div className="label">Sub County</div>
                <div className="value">{sub_county || ""}</div>
              </section> */}
            </div>
            <div className="details-section">
              <h3 className="section-title">Contact Details</h3>
              <section className="details-grid">
                <div className="label">Phone</div>
                <div className="value">{phone_number || null}</div>
              </section>
              
              <section className="details-grid">
                <div className="label">WhatsApp</div>
                <div className="value">{whatsap_number || ''}</div>
              </section>
              <section className="details-grid">
                <div className="label">Email Address</div>
                <div className="value">{email || ""}</div>
              </section>
              {/* <section className="details-grid">
                <div className="label">Physical Address</div>
                <div className="value">
                  {district && sub_county ? district + "," + sub_county : ""}
                </div>
              </section> */}
            </div>
            <div className="details-section">
              <h3 className="section-title">{reasonsFortheService}</h3>
              <section className="details-grid">
                <div className="label">{typeOfService}</div>
                <div className="value">{selectedLabTest ?? null}</div>
              </section>
              <section className="details-grid">
                <div className="label">Additional Information</div>
                <div className="value">
                  {LabTestDone.AddedAdditionalInformation || null}
                </div>
              </section>
            </div>
            <div className="details-section">
              <h3 className="section-title">{preferences}</h3>
              <section className="details-grid">
                <div className="label">Sample collection date</div>
                <div className="value">
                  {formatDate(LabTestDone.SeletedDateAndTime, "long") ||
                    "MM/DD/YY"}
                </div>
              </section>
              <section className="details-grid">
                <div className="label">Sample collection date</div>
                <div className="value">
                  {LabTestDone.SeletedTime || "HH:MM AM/PM"}
                </div>
              </section>
              <section className="details-grid">
                <div className="label">Collect sample from</div>
                <div className="value">{collectSampleFrom || null}</div>
              </section>
              {GetTo !== null && (
                <section className="details-grid">
                  <div className="label">Send HIV results on email</div>
                  <div className="value">{getResultsFrom || null}</div>
                </section>
              )}
            </div>
          </div>
          <div className="consent-wrapper">
            <label className=" mb-4 flex justify-start  gap-2">
              <input
                type="checkbox"
                checked={consent}
                onChange={handleConsentChange}
                className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"

              />
              By submitting this form, I consent to the collection and use of my
              personal and medical information for service delivery.
            </label>
            {consentError && !consent && <p className="error">Check box field is required</p>}
            <div className="submit-button-wrapper">
              <button
                onClick={success}
                className="submit-button"
                disabled={create_loading}
              >
                {loading ? (
                  <div className="loader-wrapper">
                    <Loader />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              <div className="flex items-center">
                {create_error && (
                  <div className="error-message">
                    <span>{create_error}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isSuccess && (
        <div className="success-wrapper">
          <OnSuccessPage
            onNext={() => ""}
            onPrevious={() => goToDasboard("/dashboard")}
          />
        </div>
      )}
    </div>
  );
}
