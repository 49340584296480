import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import profileIcon from '../../assests/icons/User.svg';
import arrowIcon from '../../assets/headerImages/arrow.png';
import YourAccount from 'layout/patient/YourAccount'; 
import logo from '../../assets/headerImages/Layer 2.png';
import useStates from 'hooks/useStates';
import '../../assets/TopHeader.css';

const Header: React.FC = () => {
  const { first_name, gender } = useStates();
  const navigate = useNavigate();

  return (
    <header className="header-background">
      <div className="header-content">
        <div className="logo-container" onClick={() => navigate('/dashboard')}>
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="profile-container">
          <img src={profileIcon} alt="Profile" className="profile-image" />
          <span className="profile-name">
            {gender ? (gender === 'M' ? 'Mr.' : 'Ms.') : ''} {first_name || ''}
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
