import React from 'react';

import MapImage from '../../assests/icons/Map.svg';
import VectorImage from '../../assests/icons/Vector.svg';



interface ViewProps {

    onClick: React.MouseEventHandler<HTMLDivElement>,
    active: string,

}

const ViewTab: React.FC<ViewProps> = ({ onClick, active }) => {


    return (<div className='flex mt-8'>

        <div className='flex items-center cursor-pointer'
            onClick={onClick}
        >
            <img src={VectorImage} />
            <div className='ml-1'>List View</div>
        </div>


        <div className='flex ml-[20%] w-[50%] items-center cursor-pointer'>
            <img src={MapImage} />
            <div className='ml-1'>Map view</div>
        </div>



    </div>);


}

export default ViewTab;