import React, { useState } from "react";
import PrescriptionTable from "components/Prescription/PrescriptionTable";
import { IoSearch } from "react-icons/io5";
import RequestRefill from "components/Prescription/RequestRefill";
import '../../../../assests/styles/medication.css'

export default function MyMedications() {
  const [refillRequest, setRefillRequest] = useState(true);

  return (
    <div className="medication-container">
      <div className="flex flex-col">
        <div className="flex md:gap-10 md:flex-row flex-col">
          <div className="w-full">
            <h3 className="topographyText text-[#000] text-[30px] md:text-[45px] font-[500]">
              My Medications
            </h3>
          </div>
          <div className="pt-2 relative text-gray-600">
            <input
              className="border-2 border-[#D0D2E0] bg-white px-8 h-10 md:px-5 pr-40 md:pr-16 rounded-[50px] text-sm focus:outline-none"
              type="search"
              name="search"
              placeholder="Search by medication name, Dr name"
            />
            <button className="absolute right-0 top-0 mt-5 mr-4 text-[#D0D2E0] hover:text-gray-500 focus:outline-none hidden md:flex">
              <IoSearch />
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center mt-10">
          <div>
            <p className="topographyText text-[20px] font-[400] gap-1">
              Filter By{" "}
              <button className="border-2 border-[#D0D2E0] px-8 md:px-8 md:pr-16 pr-40 rounded-[10px]">
                Active
              </button>
            </p>
          </div>
          <div className="hidden md:flex">
            <p className="topographyText flex gap-1 text-[#838383] font-[400]">
              Showing <span>3</span> of <span>10</span> prescriptions
            </p>
          </div>
        </div>
        <div className="mt-6 relative w-full">
          <PrescriptionTable />
        </div>
      </div>
    </div>
  );
}
