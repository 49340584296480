import { useState } from "react";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import SelectDropdown from "./selectmedcenter";
import "../../assests/styles/request-lab-service.css";
import { StepProps } from "./stepsProps";
import useRequestLabService from "hooks/useRequestLabService";
const RequestLabService5: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const [collectSampleFrom, setCollectSampleFrom] = useState<string | null>(
    null
  );
const {setCollectSampleFrom1,dispatch} = useRequestLabService()
  const handleOptionClick = (option: string) => {
    setCollectSampleFrom(option);
    dispatch(setCollectSampleFrom1(option))
  };
  if (collectSampleFrom !== null) {
    onNext();
  }
console.log(collectSampleFrom)
  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something ?
        </div>
      </div>
      <div className="heading2">
        Where do you want us to collect your sample from?
      </div>

      {/* <p>Select your preferred healthcare provider for sample collection, 
                and we will schedule a visit at a date and time convenient for them. Please note that the available time slots may vary depending on the provider's schedule</p> */}

      <div className="sel-test">
        {["Home", "workplace", "Health Facility"].map((option, index) => {
          return (
            <button key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default RequestLabService5;
