import React from "react";
import ChatHeader from "./ChatHeader";
import ChatUserProfileDetails from "./ChatUserProfileDetails";
import MessageSent from "./MessageSent";
import MessageSendBox from "./MessageSendBox";
import MessageRecieved from "./MessageRecieved";
export default function ChatMainBox() {
  return (
    <div className="border-[#F4F4F4] border-2 w-[100%]  ">
      <div className=" md:flex md:flex-col  gap-8  ">
        <div className="shadow-sm">
          <div className="p-2">
            {" "}
            <ChatHeader />
          </div>
        </div>
        <div className="border-b-2 border-[#F4F4F4]">
          <ChatUserProfileDetails />
        </div>
        <div className="border-b-2 border-[#EEE] flex justify-between flex-col">
          <div className="pl-10 md:pl-[100px]">
            <MessageSent />
          </div>
          <div className=" md:pr-20">
            <MessageRecieved />
          </div>
          <div className="h-20 p-4 ">
            <MessageSendBox />
          </div>
        </div>
      </div>
    </div>
  );
}
