import Header from "./header";
import LargeScreenNavBar from "./LargeScreenNavBar";
import NetLifePatientApp from "../../views/patients/pages/patientApp";
import "../../assests/styles/main_styles.css"
import WarningModal from "components/shared/warningModal";
import localStorageService from 'services/localStorageService';
import RequestService from 'services/requestService';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { setUserInfo, setIsAuthenticated } from '../../Features/auth/auth.slice';
import PersonalDataUpdateReminder from "components/shared/PersonalDataUpdateReminder";
import useStates from "hooks/useStates";
const AuthenticatedPatient = () => {
    const isAllowed = useSelector((state: any) => state.auth.isAllowed);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isUserInfoCompleted,email} = useStates()

    const handleLogout = async () => {
        // Your logout logic here
        await RequestService.getMethod('logout');
        dispatch(setUserInfo({}));
        dispatch(setIsAuthenticated(false));
        navigate('/login');
    };

    const clearStorage = () => {
        localStorageService.clearStorage('user');
    };

    return (

        <div className="w-full">
            <WarningModal />
            
            <PersonalDataUpdateReminder personalDataCompleted={isUserInfoCompleted()} isNotAllowed={isAllowed} /> 
            <Header />
            <div className='flex w-full'>
                <LargeScreenNavBar />

                <div className="content-container" >
                    <NetLifePatientApp />
                </div>
            </div>
        </div>
    )
}

export default AuthenticatedPatient;