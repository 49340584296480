import React, { useState, useEffect } from "react";
import ProgressCircle from "../models/ProgressCircle";
import { useNavigate } from "react-router-dom";
import { ProfileData } from "../models/ProgressCircle";
import "../../assests/styles/WelcomeCard.css"

export default function WelcomeCard({
  first_name,
  email_verified_at,
  phone_verified_at,
  isUserInfoCompleted,
}: any) {
  const [isProfileComplete, setIsProfileComplete] = useState<boolean>(false);
console.log(email_verified_at)
console.log(isUserInfoCompleted)
  const profileData: ProfileData =  {
    filled_all_form: { value: isUserInfoCompleted, weight: 50 },
    email_verified_at: { value: email_verified_at, weight: 25 },
    phone_verified_at: { value: phone_verified_at, weight: 25 },
  };

  // function isProfileCompleted(data: ProfileData): boolean {
  //   for (let key in data) {
  //     if (
  //       data[key as keyof ProfileData].value === "" ||
  //       data[key as keyof ProfileData].value === null ||
  //       data[key as keyof ProfileData].value !== false
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }
 console.log({
  email_verified_at,
  phone_verified_at
 })
  useEffect(() => {
    if(isUserInfoCompleted ===true && email_verified_at !==null && phone_verified_at !==null){
      setIsProfileComplete(true);
    }
    
  }, [email_verified_at, phone_verified_at, isUserInfoCompleted]);

  const navigate = useNavigate();

  const getCurrentFormattedDate = (): string => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const currentDate = getCurrentFormattedDate();

  const getGreeting = (): string => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour < 17) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };
console.log(isProfileComplete)
  return (
    <div className="welcome-card-container">
      <div className="welcome-wrapper">
        <div className="flex flex-col md:flex-row justify-between items-center w-full">
          <div className="flex flex-col gap-2">
            <div>
              <p className="welcome-date">{currentDate}</p>
            </div>
            <div>
              <h3 className="welcome-greeting">
                {getGreeting()}, {first_name}
              </h3>
              <p className="welcome-message">
                How are you feeling today? If you feel you are not okay, please
                see the doctor.
              </p>
            </div>
          </div>
          {!isProfileComplete ? (
            <div
              className="profile-completion"
              onClick={() => navigate("/add-details")}
            >
              <ProgressCircle profileData={profileData as any} />
              <button className="complete-profile-button">
                Complete your profile
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
