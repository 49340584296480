// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
// import SelectDropdown from "./selectmedcenter";
// import DateInput from "./DateInput";
// import TimeInput from "./TimeInput";
// import TimeInput2 from "./TimeInput2";
// import TextInput from "./TextInput";
// import { StepProps } from "./stepsProps";
// import "../../assests/styles/request-lab-service.css";
// import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
// import { setLabTestDoneDateAndTime, setLabTestDoneTime, setLabTestDoneOption, } from "Features/app/RequestLabService/requestLabServices.slice";
// type RequestLabServiceActions ={
//   title:string | null
//   date_title:string | null
//   additional_Info:string | null
// }
// const ServicesPreferences: React.FC<StepProps & RequestLabServiceActions> = ({ onNext, onPrevious,title,date_title,additional_Info }) => {
//   const dispatch = useDispatch<any>();
//   const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
//   const [selectedDate, setSelectedDate] = useState<string | null>(null);
//   const [selectedTime, setSelectedTime] = useState<string | null>(null);
//   const [additionalInfo, setAdditionalInfo] = useState<string>("");
//   const [errors, setErrors] = useState<{ [key: string]: string }>({});

//   const validateFields = () => {
//     const newErrors: { [key: string]: string } = {};

//     if (!selectedFacility) newErrors.selectedFacility = "Please select a facility.";
//     if (!selectedDate) newErrors.selectedDate = "Please select a date.";
//     if (!selectedTime) newErrors.selectedTime = "Please select a time.";

//     setErrors(newErrors);

//     return Object.keys(newErrors).length === 0;
//   };
// useEffect(()=>{
//   dispatch(getFacilities())
// },[])
//   const handleSubmit = () => {
//     if (validateFields()) {
//       dispatch(setLabTestDoneOption(selectedFacility));
//       dispatch(setLabTestDoneDateAndTime(selectedDate));
//       dispatch(setLabTestDoneTime(selectedTime));
//       console.log("Form submitted!");
//       onNext();
//     }
//   };

//   return (
//     <div className="request-lab-container">
//       <div className="nav-back">
//         <div onClick={onPrevious}>
//           <img src={Arrowleft} alt="arrow-left" />
//           Forgot something?
//         </div>
//       </div>

//       <div className="heading2">{title ||null}</div>
//       <p>Choose the lab facility that best suits your needs.</p>
//       <SelectDropdown value={selectedFacility} onChange={setSelectedFacility} />
//       {errors.selectedFacility && <p className="error">{errors.selectedFacility}</p>}

//       <div className="heading4">Date and Time</div>
//       <p>{date_title}</p>
//       <DateInput value={selectedDate} onChange={setSelectedDate} />
//       {errors.selectedDate && <p className="error">{errors.selectedDate}</p>}

//       <p>Select time</p>
//       <TimeInput value={selectedTime} onChange={setSelectedTime} />
//       {errors.selectedTime && <p className="error">{errors.selectedTime}</p>}

//       <div className="heading4">Additional Information</div>
//       <p>{additional_Info}</p>
//       <TextInput value={additionalInfo} onChange={setAdditionalInfo} />

//       <button className="submit-button" onClick={handleSubmit}>
//         Confirm Order
//       </button>
//     </div>
//   );
// };

// export default ServicesPreferences;


// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import Arrowleft from "../../assests/icons/Arrowleft.svg";
// import SelectDropdown from "./selectmedcenter";
// import DateInput from "./DateInput";
// import TimeInput from "./TimeInput";
// import TimeInput2 from "./TimeInput2";
// import TextInput from "./TextInput";
// import { StepProps } from "./stepsProps";
// import "../../assests/styles/request-lab-service.css";
// import { getFacilities } from "Features/app/RequestLabService/RequestLabServiceActions";
// import { setLabTestDoneDateAndTime, setLabTestDoneTime, setLabTestDoneOption, } from "Features/app/RequestLabService/requestLabServices.slice";
// type RequestLabServiceActions ={
//   title:string | null
//   date_title:string | null
//   additional_Info:string | null
// }
// const ServicesPreferences: React.FC<StepProps & RequestLabServiceActions> = ({ onNext, onPrevious,title,date_title,additional_Info }) => {
//   const dispatch = useDispatch<any>();
//   const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
//   const [selectedDate, setSelectedDate] = useState<string | null>(null);
//   const [selectedTime, setSelectedTime] = useState<string | null>(null);
//   const [additionalInfo, setAdditionalInfo] = useState<string>("");
//   const [errors, setErrors] = useState<{ [key: string]: string }>({});

//   const validateFields = () => {
//     const newErrors: { [key: string]: string } = {};



import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Arrowleft from "../../assests/icons/Arrowleft.svg";
import SelectDropdown from "./selectmedcenter";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
import TextInput from "./TextInput";
import { StepProps } from "./stepsProps";
import "../../assests/styles/request-lab-service.css";
import {
  getFacilities,
} from "Features/app/RequestLabService/RequestLabServiceActions";
import {
  setLabTestDoneDateAndTime,
  setLabTestDoneTime,
  setLabTestDoneOption,
} from "Features/app/RequestLabService/requestLabServices.slice";
import TimeZoneandDate from "components/shared/TimeZoneandDate";
import TimeZoneAndDate from "components/shared/TimeZoneandDate";

type RequestLabServiceActions = {
  title: string | null;
  date_title: string | null;
  additional_Info: string | null;
};

const ServicesPreferences: React.FC<StepProps & RequestLabServiceActions> = ({
  onNext,
  onPrevious,
  title,
  date_title,
  additional_Info,
}) => {
  const hivTest = useSelector(
    (state: any) => state.requestLabService

  );
  const dispatch = useDispatch<any>();
  const [selectedFacility, setSelectedFacility] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};

    if (!selectedFacility)
      newErrors.selectedFacility = "Please select a facility.";
    if (!selectedDate) newErrors.selectedDate = "Please select a date.";
    if (!selectedTime) newErrors.selectedTime = "Please select a time.";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onPageNext = () => {

    const isValidated = validateFields();

    if (isValidated) {
      onNext();
    }

    window.scrollTo(0, 0);
  }

  useEffect(() => {
    dispatch(getFacilities());
  }, [dispatch]);



  dispatch(setLabTestDoneOption(selectedFacility));
  dispatch(setLabTestDoneDateAndTime(selectedDate));
  dispatch(setLabTestDoneTime(selectedTime));




  return (
    <div className="request-lab-container">
      <div className="nav-back">
        <div onClick={onPrevious}>
          <img src={Arrowleft} alt="arrow-left" />
          Forgot something?
        </div>
      </div>

      <div className="heading2">{title || null}</div>
      <p>Choose the lab facility that best suits your needs.</p>
      <SelectDropdown value={hivTest.LabTestDone.SeletedOption || selectedFacility} onChange={setSelectedFacility} />
      {errors.selectedFacility && (
        <p className="error">{errors.selectedFacility}</p>
      )}

      <div className="heading4">Date and Time</div>
      {/* <p>{date_title}</p> */}
      <TimeZoneAndDate
        setTime={setSelectedTime}
        setDate={setSelectedDate}
        selectedDate={hivTest.LabTestDone.SeletedDateAndTime}
        selectedTime={hivTest.LabTestDone.SeletedTime}
      />
      {errors.selectedDate && <p className="error">{errors.selectedDate}</p>}
      {errors.selectedTime && <p className="error">{errors.selectedTime}</p>}

      <div className="heading4">Additional Information</div>
      
      <TextInput value={hivTest.LabTestDone.AddedAdditionalInformation || additionalInfo} onChange={setAdditionalInfo} />

      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={onPageNext}

        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ServicesPreferences;
