
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "../../assests/styles/registration.css";

// Define a CSS class for focus styles
const focusStyle = `
  .custom-focus:focus {
    border: 1px solid #8a3ffc !important;
    outline: #8a3ffc !important;
  }
`;

interface PhoneInputComponentProps {
  name: string;
  value: string;
  onChange: (e: string) => void;
  error?: string; // Add error prop
  className?: string; // Add className prop
  style?: React.CSSProperties; // Add style prop for inline styles
}

const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({ name, value, onChange, error, className }) => {

  const handlePhoneChange = (phoneValue: string, countryData: { countryCode: string }) => {
    if (phoneValue.replace(/\D/g, '').length > 12) {
      return; // Prevent further input if length exceeds 12 digits
    }
    onChange(phoneValue);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const digitsOnly = value.replace(/\D/g, ''); // Current digits in the input
    if (digitsOnly.length >= 12 && !['Backspace', 'Delete'].includes(e.key)) {
      e.preventDefault(); // Prevent typing if the limit is reached
    }
  };

  return (
    <div>
      {/* Inject the focus style into the document */}
      <style>{focusStyle}</style>

      <PhoneInput
        country={'ug'}
        value={value}
        onChange={handlePhoneChange}
        inputStyle={{
          width: '100%',
          height: '42px',
          borderWidth: '1px',
          borderColor: '#d4d7e3'
        }}
        // @ts-ignore
        onKeyDown={handleKeyPress}
        // Add the custom class to the input
        inputClass={className ? `${className} custom-focus` : 'custom-focus'}
        onlyCountries={['ug']} // Restrict to Uganda country code only
      />
    </div>
  );
};

export default PhoneInputComponent;
