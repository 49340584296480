import React, { useEffect, useState } from "react";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import ConsentDatause from "./RequestLabServiceComponents/ConsentDatause";
import { StepProps } from "components/RequestLabService/stepsProps"; // Import added
import AdditionalInformation from "./RequestLabServiceComponents/AdditionalInformation";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import { useDispatch, useSelector } from "react-redux";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
interface DeliveryPreferencesProps extends StepProps {
  // Extended StepProps interface
  onPrevious: () => void;
  onNext: () => void;
}

const DeliveryPreferences: React.FC<DeliveryPreferencesProps> = ({
  onPrevious,
  onNext,
}) => {
  const HealthProductsData = useSelector(
    (state: any) => state.hivCare.Health_Products
  );

  const [deliveryMethod, setDeliveryMethod] = useState<string>(HealthProductsData.result_delivery_method);
  const [date, setDate] = useState<string>(HealthProductsData.preferred_date);
  const [preferedTime, setpreferedTime] = useState<string>(HealthProductsData.preferred_time);
  const [additionalInfo, setAdditionalInfo] = useState<string>(HealthProductsData.additional_information);
  const [facility_id, setFacilityId] = useState<number>(HealthProductsData.facility_id);
  // const [enabled, setEnabled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { GetHealthproducts } = useHivCare();


  var displayError = false;
  const setError = () => {
    displayError = false;
    if (
      deliveryMethod === "" &&
      preferedTime === "" &&
      facility_id == null &&
      date === ""
    ) {
      displayError = true;
      return "Please select all the required fields";
    }

    displayError = false;
    return;
  };



  useEffect(() => {

    GetHealthproducts.healthDeliveryDetails(
      dispatch,
      deliveryMethod,
      date,
      preferedTime,
      additionalInfo,
      facility_id
    );

  }, [deliveryMethod, date, facility_id, preferedTime, additionalInfo])


  return (
    <div className="request-lab-container">
      <FacilitySelector selectedfacilityId={setFacilityId}  id={facility_id}/>
      <h3 className="heading3">Preferred Delivery Method</h3>
      <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] space-y-4">
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Home"
            checked={deliveryMethod === "Home"}
            onChange={() => setDeliveryMethod("Home")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Home</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Workplace"
            checked={deliveryMethod === "Workplace"}
            onChange={() => setDeliveryMethod("Workplace")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Workplace</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Health Facility"
            checked={deliveryMethod === "Health Facility"}
            onChange={() => setDeliveryMethod("Health Facility")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Health Facility</span>
        </label>
      </div>
      <h3>Select Facility</h3>
      <FacilitySelector  selectedfacilityId={setFacilityId}  id={facility_id}/>

      {/* <h3>Preferred Delivery Date:</h3> */}
      <div className="w-full">
        <DeliveryDateTime selectedDate=""  selectedTime="" setDate={setDate} setTime={setpreferedTime} />
      </div>
      <h3 className="add_info ">Additional Information:</h3>

      <div className="mb-6 mt-2">
        <AdditionalInformation setData={setAdditionalInfo} value={additionalInfo} />
      </div>
      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={onNext}

        >
          Next
        </button>
      </div>
      <div className="mt-4">
        <ErrorComponent message={setError()} showError={displayError} />
      </div>
    </div>
  );
};

export default DeliveryPreferences;
