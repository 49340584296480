import React from "react";
import { logo } from "assests/dashBoard";
import listIcons from "../../assests/icons/Layer 2(1).svg";
import profile from "../../assests/images/Ellipse 69.svg";
import { FaLongArrowAltLeft } from "react-icons/fa";
export default function ChatHeader() {
  return (
    <div className="p-2">
      <div className="flex  items-center md:justify-between">
        <div className="flex gap-4">
          <div className="flex items-center gap-4 ">
            <span className="text-primary_color md:hidden">
              {" "}
              <FaLongArrowAltLeft />
            </span>
            <img
              src={profile}
              alt="logo"
              className="w-[50px] h-[50px] rounded-[50%]"
            />
          </div>
          <div className="flex flex-col justify-center items-center ">
            <h3 className="topographyText font-[600] flex gap-2 text-[20px] items-center">
              Dr mike james{" "}
            </h3>
            <p className="topographyText text-[20px] font-[400] text-[#596780] ">
              XXX Medical Center
            </p>
          </div>
        </div>

        <div className="hidden md:flex">
          <img src={listIcons} alt="list" className="w-[20px] h-[20px]" />
        </div>
      </div>
    </div>
  );
}
