import React, { ChangeEventHandler, useState } from 'react';



interface InputFieldProps {
    value: string,
    setInputField: Function,
    title: string,
    placeholder: string,
    type:string,
    name:string
}

const InputField: React.FC<InputFieldProps> = ({ value, setInputField, title, placeholder ,name,type}) => {


    return (<div className='mt-3'>
        <div>
            <label>{title}</label>
        </div>
        <div className='mt-2 mb-4'>
            <input
                type={type}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputField(event.target.value)}
                placeholder={placeholder}
                className="border-2 w-[100%] border-customGray  p-3 rounded-lg focus:outline-none focus:border-blue-500"
                name={name}
            />
        </div>
    </div>);


}

export default InputField;