import React,{useEffect} from "react";
import { useSelector ,useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../../assests/styles/SummaryPage.css";
import ConsentDatause from "components/RequestLabServiceComponents/ConsentDatause";
import OnSuccessPage from "components/RequestLabService/OnSuccessPage";
import { setCreateSuccess } from "Features/app/RequestLabService/HivCare.slice";
type SummarySectionProps = {
  service: string
  data: [{
    serviceType: string;
    questions: {
      title: string;
      items: {
        label: string;
        value: string;
      }[];
    }[];
  }] | []
};
const PrepSummaryPage: React.FC<
  { onSubmit: () => void } & SummarySectionProps
> = ({ onSubmit, data, service }) => {
  const isSuccess = useSelector((state: any) => state.hivCare.createSuccess);
  const goToSuccessPage = useNavigate();
const dispatch = useDispatch()
  useEffect(() => {
 
    const timer = setTimeout(() => {
   
      dispatch(setCreateSuccess(false));
     if(isSuccess){
      goToSuccessPage("/dashboard")
     }
    
    },10000);
   
    return () => clearTimeout(timer);
  
  
  
},[isSuccess,goToSuccessPage,dispatch])
  return (
    <div>
      {
        isSuccess ? (
          <OnSuccessPage onNext={() => ''} onPrevious={() => ''} />
        ) : (
          <div className="prep-summary-page-container">
            <h2>
              {data.find((service) => service.serviceType)?.serviceType as any}
            </h2>
            {data.map((section) => (
              <div key={section.serviceType}>
                {section.questions.map((question, index) => (
                  <div className="prep-summary-section" key={index + section.serviceType + 'innner-elem'}>
                    <h3>{question.title}</h3>
                    {question.items.map((item, index) => (
                      <div key={index + 'deeper' + question.title}>
                        <p>
                          <strong>{item.label}:</strong> {Array.isArray(item.value) ? item.value.join(', ') : item.value}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}

            <div className="prep-summary-section">
              <ConsentDatause service={service} />
            </div>
          </div>

        )
      }
    </div>
  )
};

export default PrepSummaryPage;
